@media only screen and (max-width: 1919px) {
  /*create event*/
  .map-img img {
    width: 100%;
  }
  /*Event Listing*/
  .event-listing-service-list ul li {
    margin-left: 40px;
    padding-left: 40px;
  }
} /*1680*/
@media only screen and (max-width: 1679px) {
  /*page auction vendor   */
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 11.4px 100px;
  }
  /*setting page*/
  .setting-list ul:last-child {
    margin-left: 12%;
  }
  .setting-list ul li {
    margin-bottom: 13px;
  }
  /*Analytics Review*/
  .analytics-tabber-list {
    margin-left: 25px;
  }
  .analytics-tabber-number,
  .analytics-tabber-arrow {
    width: 48%;
  }
  .analytics-tabber-content figure figcaption p {
    margin-left: 38px;
  }
  /*Vendor Consumer*/
  .vendor-child-friendly-block ul li {
    margin-left: 135px;
  }
  .vendor-consumer-links {
    padding: 65px 0 65px;
  }
  /*Event Listing */
  .event-listing-budget-block,
  .event-listing-service-block,
  .event-listing-consumer-block {
    width: 100%;
  }
  .event-details .event-listing-budget-block, .event-details  .event-listing-service-block{
    width: 100%;
  }
  /* .event-listing-type {
    width: 26%;
  } */
  /* .event-listing-date {
    width: 73%;
  } */
  .event-listing-wrapper {
    padding: 10px 0 40px;
  }
  .event-brief-description-head {
    padding-left: 0;
  }
  .event-listing-service-list ul li {
    margin-left: 25px;
    padding-left: 25px;
  }
  /*Privacy*/
  .privacy-policy-wr {
    padding: 90px 0 0;
  }
} /*1440*/
@media only screen and (max-width: 1439px) {
  /*  vendor profile*/
  .photography-details ul li p {
    margin-left: 105px;
  }
  /*Edit Events*/
  .event-form-name-block label {
    width: 39%;
  }
  .event-form-members-block label {
    width: 60%;
  }
  .event-form-members-block input {
    width: 40%;
  }
  .event-form-name-block input {
    width: 61%;
  }
  .next-btn-sec .btn {
    margin-top: 10px;
  }
} /*1366*/
@media only screen and (max-width: 1365px) {
  .fav-vendors-block {
    width: 31%;
    margin: 0 17px 17px 0;
  }
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 11.4px 86px;
  }
  .suggested-your-bid-content a {
    margin-left: 12px;
  }
  .next-auction {
    margin-left: 11px;
  }
  .acc-spot-bit-txt h4 {
    width: 62%;
  }
  .acc-spot-bit-txt p {
    width: 35%;
  }
  .next-auction-content {
    margin-left: 20px;
  }
  /*setting page*/
  .setting-list ul:last-child li {
    padding: 5px 11px 5px 11px;
    text-align: center;
  }
  .setting-inner-card {
    padding: 28px 15px 38px 15px;
  }
  .setting-list ul li {
    margin-bottom: 26px;
    padding: 5px 0px 5px 0px;
  }
  .setting-list ul li a {
    font-size: 14px;
  }
  .setting-list ul li {
    margin-bottom: 16px;
  }
  .setting-inner-card {
    width: 46.4%;
  }
  .suggested-your-bid-content a {
    margin-left: 12px;
  }
  .setting-inner-card.premium-card {
    margin-left: 10px;
  }
  .Subscription_details .right-content-block .setting-cards  .setting-inner-card.premium-card  {
    margin-left: 25px !important;
  }
  .setting-cards {
    margin-left: 0%;
  }
  .setting-list ul:last-child {
    margin-left: 7%;
  }
  /*create event*/
  .event-sec.service-request .checkbox-label {
    letter-spacing: 2px;
    padding: 0 0 0 28px;
  }
  .event-sec.service-request ul {
    gap: 15px;
  }
  .other-detail-list h4.common-label {
    letter-spacing: 2px;
    font-size: 14px;
  }
  .other-detail-feild {
    margin-left: 23px;
  }
  .other-detail-feild label {
    letter-spacing: 2px;
  }
  /*vendor profile page*/
  .photography-details ul li p {
    margin-left: 59px;
  }
  .photography-details ul li {
    margin-right: 1px;
  }
  .service-type.event-sec ul {
    gap: 40px;
  }
  .service-type.event-sec ul {
    padding-bottom: 25px;
  }
  .vendor-category ul {
    padding-bottom: 40px;
  }
  .vendor-category ul {
    padding-bottom: 40px;
  }
  .portfolio-slider {
    display: block;
  }
  /*auction-vendor page*/
  .suggested-your-bid-content:nth-child(2) p {
    width: 24%;
  }
  .user-review-img > figure {
    width: 10%;
  }
  .user-review-img .reviewer-review {
    width: 89%;
  }
  /*Personal Information*/
  .information-feild-email label {
    width: 57%;
  }
  .information-feild-email input {
    width: 43%;
  }
  .information-feild-phone label {
    width: 59%;
  }
  .information-feild-phone input {
    width: 41%;
  }
  .personal-infor-one-full-feild .comn-form-feild:nth-child(2n) {
    margin-right: 29px;
  }
  /*Edit Events*/
  .sidebar-content-wr.edit-events-sidebar .container {
    max-width: 100%;
  }
  .event-form-name-block label {
    width: 40%;
  }
  .event-form-name-block input {
    width: 60%;
  }
  .event-form-members-block label {
    width: 62%;
  }
  .event-form-members-block input {
    width: 38%;
  }
  /*Consumer Details*/
  .event-details-block:nth-child(2) p,
  .event-details-block:nth-child(2) h4 {
    width: 19.6%;
  }
  /*Recent Reviews*/
  .sidebar-content-wr.recent-review-wr .container {
    max-width: 100%;
  }
  .recent-review-content-block .user-review-img .reviewer-review {
    width: 89%;
  }
  /*Analytics Review */
  .analytics-tabber-list {
    margin-left: 7px;
  }
  .analytics-tabber-list {
    padding: 26px 14px;
    width: 24%;
  }
  /*Analytics Review*/
  .analytics-tabber-content figure figcaption p {
    margin-left: 22px;
  }
  /*Vendor Consumer*/
  .vendor-consumer-img-name h3 {
    font-size: 27px;
    line-height: 34px;
  }
  .vendor-child-friendly-block ul li {
    margin-left: 80px;
  }
  .vendor-popup-slider-inner .owl-nav button.owl-next {
    right: -65px;
  }
  /*Event Listing*/
  .event-listing-content {
    margin: 0 auto;
    width: 95%;
  }
  /*Privacy*/
  .privacy-policy-banner-img img {
    height: 250px;
    object-fit: cover;
  }
  .user-review-block.tabber-content.tab1 button.btn.btn_normal {
    font-size: 12px !important;
  }
  .chats-textarea-form {
    display: flex;
  }
} /*1200*/
@media only screen and (max-width: 1199px) {
  .owl-carousel .owl-item .vendor-photo-carousel {
    display: block !important;
    width: 100% !important;
    height: 260px !important;
  }
  .right-content-block {
    padding-left: 20px;
  }
  .fav-vendors-block {
    width: 47%;
  }
  /*setting page*/
  .setting-inner-card.premium-card {
    margin-left: 3%;
  }
  .setting-inner-card {
    width: 48%;
  }
  .setting-list ul:last-child li {
    padding: 5px 5px 5px 5px;
  }
  .setting-list ul:last-child {
    margin-left: 3%;
  }
  .acc-spot-bit-txt h4 {
    width: 85%;
  }
  .acc-spot-bit-txt p {
    width: 13%;
  }
  .next-auction-content h6 {
    margin-left: 18px;
  }
  .next-auction-content {
    margin-left: 0;
  }
  .suggested-your-bid-content h4 {
    width: 45%;
  }
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 11px 75px;
    margin-left: 10px;
  }
  /*vendor profile*/
  .vendor-category ul {
    gap: 20px;
  }
  .photography-details ul li span {
    margin-right: 10px;
  }
  .event-budget-block.photography-details ul {
    padding: 0px 0px 20px 0px;
  }
  .photography-details ul li p {
    margin-left: 54px;
  }
  /*auction-vendor page*/
  .suggested-your-bid-content h4 {
    width: 46%;
  }
  /*PAGE user-profile-review*/
  .user-review-left,
  .recent-review-content-block .user-review-left {
    width: 35%;
  }
  .user-review-right {
    width: 64%;
    padding: 15px 0 0 20px;
  }
  .user-review-img .reviewer-review {
    width: 88%;
  }
  /*create event page*/
  .other-detail-feild {
    margin-left: 19px;
  }
  .other-detail-feild label {
    letter-spacing: 1px;
  }
  /*consumer*/
  .security-setting {
    margin: 37px 0px 0px 4%;
  }
  /*Personal Information*/
  .personal-information-sidebar .container {
    max-width: 100%;
  }
  /*Edit Events*/
  .event-form-name-block label {
    width: 45%;
  }
  .event-form-name-block input {
    width: 55%;
  }
  .event-form-date-block label {
    width: 43%;
  }
  .event-form-date-block input {
    width: 57%;
  }
  .event-form-members-block label {
    width: 70%;
  }
  .event-form-members-block input {
    width: 30%;
  }
  /*Consumer Details*/
  .sidebar-content-wr.consumer-details-sidebar .container {
    max-width: 100%;
  }
  /*chat start*/
  .chats-outer-block .user-review-left {
    width: 30%;
  }
  .chats-textarea-form {
    width: 90%;
  }
  .chats-textarea {
    width: 88%;
  }
  /*chat end*/
  /*Analytics Review*/
  .analytics-tabber-top h4 {
    font-size: 22px;
    line-height: 28px;
  }
  .analytics-tabber-para p {
    font-size: 18px;
    line-height: 24px;
  }
  .analytics-tabber-para {
    margin-top: 10px;
  }
  .analytics-tabber-list {
    padding: 15px 9px;
    width: 23%;
  }
  .analytics-tabber-content figure figcaption p {
    margin-left: 17px;
    font-size: 18px;
    line-height: 26px;
  }
  /*Vendor Consumer*/
  .vendor-consumer-img-name h3 {
    font-size: 22px;
    line-height: 32px;
  }
  .vendor-consumer-img-name {
    width: 100%;
    margin-top: -80px;
  }
  .vendor-consumer-reviews-block {
    width: 100%;
    text-align: left;
  }
  .flag-content {
    justify-content: flex-start;
  }
  .vendor-child-friendly-block {
    padding: 24px 30px;
  }
  .vendor-child-friendly-block ul li {
    margin-left: 45px;
  }
  .area-operates-list ul li {
    padding: 15px 13px;
    margin-left: 6px;
  }
  .popup-content {
    width: 780px;
    height: 440px;
  }
  .popup-content iframe {
    height: 440px !important;
  }
  /*Vendor profile New*/
  .event-categories-heading {
    width: 33%;
  }
  .accordion-btn-for-update {
    width: 55%;
  }
  /*Event Listing*/
  .event-listing-content {
    margin: 0 auto;
    width: 100%;
  }
  .event-listing-type {
    /* width: 30%; */
  }
  .event-listing-date {
    /* width: 69%; */
  }
  .event-listing-budget-inner {
    margin-left: unset;
  }
  /* .event-listing-service-head {
    width: 19%;
  } */
  /* .event-listing-service-list {
    width: 80%;
  } */
  .event-listing-service-list ul li {
    margin-left: 20px;
    padding-left: 20px;
  }
  .event-listing-select {
    width: 21%;
  }
  /*Event Listing Details*/
  .back-to-event-list a {
    left: -30px;
  }
  .event-listing-details-wrapper .event-listing-date-inner {
    margin-left: 40px;
  }
  .event-listing-details-wrapper .event-listing-type ul li,
  .event-listing-details-wrapper .event-listing-type ul li:first-child {
    padding: 0 30px 0 0px;
    margin: 0 30px 0 0;
  }
  .event-listing-consumer-inner {
    margin-left: 35px;
  }
  .review-by-vendors-popup {
    width: 85%;
  }
  .review-by-vendors-inner {
    height: 400px;
  }
  .user-chat-top-box {
    padding: 15px 5px;
  }
  .event-listing-type ul li {
    font-size: 18px;
    padding-left: 25px;
    margin-left: 25px;
  }
} /*1024*/
@media only screen and (max-width: 1023px) {

  .common-select-sec.category-sec select {
    background-position: 16px;
  }
  .common-select-sec .form-control {
    padding: 7px 25px 7px 48px;
  }
  .event-payment-btn {
    display: block;
  }
  .accordion-btn-for-update{
    width: 100%;
  }
  .sidebar-content-wr {
    padding: 65px 0 75px 0;
  }
  .vendor-consumer-reviews-bottom {
    display: flex;
    justify-content: center !important;
}
  /*Vendor Profile Page*/
  .photography-details ul li {
    margin-right: 8px;
  }
  .photography-details ul li p {
    margin-left: 49px;
  }

  .vendor-photography .vendor-event-carousel .item img {
    height: auto;
  }
  .vendor-photography .vendor-event-carousel .owl-nav button.owl-next {
    right: 8px;
  }
  .vendor-photography .vendor-event-carousel .owl-nav button.owl-prev {
    left: -8px;
  }
  .vendor-photography .vendor-event-carousel .owl-nav {
    top: 20px;
  }
  /*comsumer page*/
  .member-since {
    margin-top: 30px;
  }
  /*Edit Events*/
  .event-form-name-block label,
  .event-form-name-block input,
  .event-form-budget-block label,
  .event-form-members-block label,
  .event-form-budget-block input,
  .event-form-members-block input,
  .event-form-date-block label,
  .event-form-date-block input,
  .event-form-members-block label,
  .event-form-members-block input {
    width: 100%;
  }
  /*chat start*/
  .chats-outer-block .user-review-right {
    width: 65%;
  }
  /*chat end*/
  /*Vendor Cosumer*/
  .vendor-child-friendly-block ul li {
    margin-left: 84px;
  }
  .area-operates-list ul li {
    margin-left: 30px;
  }
  .vendor-consumer-links {
    padding: 65px 0;
  }
  .vendor-popup-slider-inner .owl-nav button.owl-next,
  .vendor-popup-slider-inner .owl-nav button.owl-prev {
    top: auto;
    bottom: -80px;
  }
  .vendor-popup-slider-inner .owl-nav button.owl-next {
    right: 0;
  }
  .vendor-popup-slider-inner .owl-nav button.owl-prev {
    left: 0;
  }
  .vendor-popup-slider-para {
    margin-top: 65px;
  }
  /*Vendor Profile New*/
  .vendor-profile-wrapper {
    padding: 30px 0 0 20px;
  }
  /*Event Listing*/
  .event-listing-wrapper {
    padding: 40px 0 90px;
  }
  .event-listing-service-list ul li {
    margin-left: 30px;
    padding-left: 30px;
  }
  /*Event Listing Details*/
  .back-to-event-list a {
    left: -10px;
  }
  /*Privacy*/
  .privacy-policy-wr {
    padding: 90px 0 84px;
  }
} /*980*/
@media only screen and (min-width: 980px) {
  /* .area-operates-slider.swiper-wrapper {
    margin-left: -375px;
  } */
  /* .area-operates-slider-block .area-operates-slider-content {
    width: 483.96px !important;
    height: 323.17px !important;
  } */

.event-listing-service-list {
  width: 75%;
  padding-left: 22px;
}
}
@media only screen and (max-width: 979px) {
  .next-btn-sec .btn {
    min-width: 200px;
    margin-right: 16px;
  }
  .left-sidebar {
    min-height: auto;
  }
  .fav-vendors-block {
    width: 70%;
    margin: 30px 0 0 0;
  }
  .fav-vendors-block:first-child {
    margin-top: 0;
  }
  .left-sidebar {
    width: 15%;
  }
  .sidebar-icon {
    width: auto;
  }
  .sidebar-icon-block {
    margin-top: 50px;
  }
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 8px 45px;
  }
  .acc-spot-bit-txt {
    width: 100%;
  }
  .next-auction {
    margin-left: 0;
    width: 100%;
  }
  .next-auction-content {
    margin-left: 35px;
  }
  .suggested-your-bid-content {
    width: 100%;
    margin-left: 0;
  }
  .history-bid-box {
    margin-top: 20px;
  }
  .suggested-your-bid-content:nth-child(2) p {
    width: 35%;
  }
  /*setting page*/
  .setting-list ul:last-child {
    margin-left: 6%;
  }
  .setting-list ul:last-child li {
    padding: 5px 7px 5px 7px;
  }
  .setting-list ul:last-child {
    margin-left: 1%;
  }
  .setting-list ul li {
    margin-bottom: 16px;
  }
  .setting-list {
    margin: 40px auto 0px;
  }
  .setting-list,
  .setting-cards {
    display: block;
    width: 100%;
  }
  .setting-sec .right-top-text {
    text-align: center;
  }
  .setting-list ul:last-child {
    margin-left: 20px;
  }
  .setting-cards {
    margin: 30px 0px 0px 0px;
  }
  /*create event page*/
  .other-detail-list {
    width: 100%;
    display: block;
  }
  .other-detail-list .inner-block {
    padding: 8px 20px;
  }
  .other-detail-list {
    margin-bottom: 30px;
  }
  /*vendor page*/
  .service-type.event-sec ul {
    gap: 0px;
  }
  .photography-details ul li {
    margin: 15px 0px 0px 0px;
    width: 100%;
  }
  .vendor-profile-sec .john-description.common-description {
    text-align: center;
  }
  .photography-details ul li:first-child {
    margin-top: 0px;
  }
  .vendor-photography.portfolio-video h4,
  .vendor-photography.portfolio-photos h4 {
    text-align: left;
  }
  .next-auction-content {
    margin-left: 1%;
  }
  .next-auction-content h6 {
    margin-left: 8px;
  }
  .left-sidebar.sidebar-toggle .sidebar-mob-menu {
    transform: rotate(180deg);
    width: 65px;
  }
  /*Page auction-vendor */
  .right-content-block {
    width: 80%;
  }
  .suggested-your-bid-content:nth-child(2) {
    padding: 0 0 0 0px;
  }
  .history-bid {
    margin: 20px 0 20px;
  }
  .next-auction-txt {
    width: 100%;
  }
  .next-auction-content {
    margin: 10px 18% 0px 0px;
  }
  .next-auction-content:last-child {
    margin-right: 0px;
  }
  .history-bid-box,
  .history-bid-head {
    display: block;
    text-align: center;
  }
  .history-bid-head {
    margin-top: 20px;
  }
  .other-detail-feild {
    margin-left: 2%;
  }
  .other-detail-feild label {
    font-size: 13px;
  }
  .other-detail-feild input {
    width: 80%;
  }
  /*review page*/
  .right-box {
    padding: 20px 15px 40px;
  }
  .user-review-left,
  .recent-review-content-block .user-review-left {
    width: 42%;
  }
  .user-review-right {
    width: 57%;
    padding: 0px 0 0 10px;
  }
  .review-date-star .review-star {
    width: 85%;
  }
  .review-date-star,
  .reply-btn {
    width: 100%;
  }
  .reply-btn {
    text-align: left;
    margin-top: 10px;
  }
  .review-date-star .review-star {
    width: 100%;
    margin: 4px 0 0 0;
    margin-left: 0 !important;
  }
  /*consumer page*/
  .jason-profile-block {
    padding: 65px 25px 20px 25px;
  }
  .jason-profile-submit,
  .member-since {
    margin-left: 0px;
    text-align: center;
  }
  .security-password {
    text-align: center;
  }
  .member-since {
    margin-top: 25px;
  }
  /*Footer*/
  .footer-upper::before {
    content: unset;
  }
  .vendor-profile-sec h3,
  .event-sec.service-type h4,
  .event-sec.vendor-category h4 {
    text-align: center;
  }
  .left-sidebar.sidebar-toggle {
    top: 140px;
    left: 0px;
  }
  .setting-inner-card.premium-card .btn {
    padding: 11px 41px 8px 41px;
  }
  .sky-btn {
    padding: 11px 41px 10px 41px;
  }
  /*Personal Information*/
  .information-feild-first-name label,
  .information-feild-email label,
  .information-feild-first-name input,
  .information-feild-email input,
  .information-feild-last-name label,
  .information-feild-phone label,
  .information-feild-last-name input,
  .information-feild-phone input .information-feild-address input,
  .information-feild-address input,
  .information-feild-address label,
  .information-feild-country label,
  .information-feild-country input,
  .information-feild-country input,
  .information-feild-city label,
  .information-feild-city input {
    width: 100%;
  }
  .fav-vendors-block {
    margin: 30px 0 0;
    width: 100%;
  }
  .comn-form-feild label::after {
    display: none;
  }
  /*Edit Events*/
  .comn-form-feild label,
  .comn-form-feild input {
    padding: 12.5px 10px;
  }
  .event-form-location-block label {
    width: 100%;
  }
  .comn-form-feild select {
    width: 100%;
  }
  /*Consumer Details*/
  .event-details-table {
    overflow-x: scroll;
  }
  .event-details-inner {
    width: 910px;
  }
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .event-details-table {
    scrollbar-width: thin;
    scrollbar-color: #1fbcff #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .event-details-table::-webkit-scrollbar {
    width: 10px;
    height: 5px !important;
  }

  .event-details-table::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .event-details-table::-webkit-scrollbar-thumb {
    background-color: #1fbcff;
    border-radius: 10px;
  }
  .recent-review-content-block .user-review-right {
    width: 55.5%;
    margin-left: 10px;
    padding: 15px;
    padding-bottom: 70px;
  }
  .recent-review-content-block .reviewer-review-top {
    padding: 8px 12px;
  }
  .recent-review-content-block .user-review-img .reviewer-review {
    width: 88%;
  }
  /*chat start*/
  .chats-outer-block .user-review-right {
    width: 57%;
  }
  .right-content-block.chats-outer-block {
    width: 85%;
  }
  .user-review-left,
  .recent-review-content-block .user-review-left {
    width: 40%;
  }
  .user-chat-top-box {
    padding: 15px 15px;
  }
  /* .user-chat-box-left,
  .user-chat-box-right {
    width: 100%;
  } */
  .user-chat-bottom {
    padding: 35px 0;
  }
  .chats-textarea-form {
    width: 80%;
  }
  .chat-note-text {
    bottom: -60px;
  }
  /*chat end*/
  /*Analytics Review*/
  .analytics-tabber-list {
    padding: 25px 20px;
    width: 40%;
    margin: 0 0 40px 30px;
  }
  .analytics-tabber-list:nth-child(2n + 1) {
    margin-left: 0;
  }
  .analytics-tabber-select-block {
    right: 15px;
    top: 15px;
  }
  .analytics-tabber-content {
    padding: 42px 20px;
  }
  .analytics-tabber-content figure figcaption p {
    margin-left: 12px;
    font-size: 16px;
    line-height: 24px;
  }
  /*Vendor Consumer*/
  .vendor-consumer-img-name figure {
    margin: 0 auto;
  }
  .vendor-consumer-img-name {
    margin-top: 0;
    text-align: center;
  }
  .vendor-consumer-reviews-block {
    text-align: center;
  }
  .flag-content {
    justify-content: center;
  }
  .vendor-consumer-detail-wr {
    padding: 10px 0 0;
  }
  .vendor-child-friendly-block ul li {
    margin-left: 20px;
  }
  .vendor-child-friendly-block {
    padding: 24px 20px;
  }
  .area-operates-list ul li {
    margin: 0 0 20px 20px;
    width: 31%;
  }
  .area-operates-list ul li:nth-child(3n + 1) {
    margin-left: 0;
  }
  .vendor-popup-slider-content img {
    height: 330px;
    object-fit: cover;
  }
  .vendor-popup-slider-content figure figcaption img {
    height: auto;
  }
  .vendor-consumer-links {
    padding: 35px 0 50px;
  }
  .vendor-banner img {
    height: 250px;
    object-fit: cover;
  }
  .popup-content {
    width: 647px;
    height: 368px;
  }
  .popup-content iframe {
    height: 368px !important;
  }
  .area-operates-slider-content,
  .area-operates-slider-nav {
    text-align: center;
  }
  .area-operates-slider-inner.mySwiper {
    padding: 0 10px;
  }
  .user-chat-box-right {
    text-align: left;
  }
  .sidebar-content-wr.recent-review-wr.chat-sidebar-content {
    padding-bottom: 120px;
  }
  .chat-note-text {
    bottom: -90px;
  }
  /*Vendor profile New*/
  .promo-video-sec .col-md-5.col-12:first-child {
    width: 350px;
    height: 280px;
    background-color: #030a1c;
  }
  .promo-video-sec {
    margin-bottom: 50px;
  }
  /*Event Listing*/
  .event-listing-type-date {
    padding: 29px 25px;
  }
  /* .event-listing-type {
    width: 40%;
  } */
  .event-listing-date {
    width: 59%;
  }
  .event-listing-budget-inner {
    margin-left: auto;
  }
  /* .event-listing-service-head {
    width: 21%;
  } */
  /* .event-listing-service-list {
    width: 78%;
  } */

  /*Event Listing Details*/
  .event-listing-details-wrapper .event-listing-date-inner {
    margin-left: 20px;
  }
  .event-listing-details-wrapper .event-listing-type ul li,
  .event-listing-details-wrapper .event-listing-type ul li:first-child {
    padding: 0 20px 0 0px;
    margin: 0 20px 0 0;
  }
  .back-to-event-list {
    text-align: left;
    margin-bottom: 10px;
  }
  .back-to-event-list a {
    position: unset;
  }
  .event-listing-consumer-inner {
    margin: 0 0 0 30px;
  }
  .event-listing-consumer-inner:nth-child(2n + 1) {
    margin-left: 0;
  }
  /*Privacy*/
  .privacy-policy-wr {
    padding: 60px 0 54px;
  }
  .privacy-policy-text-block h3 {
    margin: 40px 0 15px;
    font-size: 22px;
    line-height: 32px;
  }
  .privacy-policy-banner-img img {
    height: 300px;
  }
  .privacy-policy-text-block p {
    margin: 20px 0 0 0;
  }
  section.sidebar-content-wr.consumer-block.event-listing-details-wrapper .right-content-block {
    width: 100%;
  }
  .desktop-view{
    display: none;
  }
  .event-listing-type ul li {
    font-size: 18px;
    padding-left: 10px;
    margin-left: 10px;
  } 
}/*768*/
@media only screen and (max-width: 767px) {
  .home-page-heading h1 {
    font-size: 19px;
    margin-top: 30px;
  }
  .warning-text{
    font-size: 20px;
    line-height: 1.4;
    font-weight: 100;
  }
  .owl-carousel .owl-item .vendor-photo-carousel {
    display: block !important;
    width: 100% !important;
    height: 200px !important;
  }
  .vendor-consumer-reviews-top{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .category-filter{
    order: 1;
  }
  .vender-data .content-css {
    font-size: 15px;
    display: none;
}
.vendor-consumer-reviews-bottom {
  margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .sidebar-mob-menu {
    position: fixed;
    top: 100px;
    left: 0;
    width: 40px;
    height: 40px;
    backdrop-filter: blur(14px);
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    background: url("../../../public/img/suggested-bid-btn.svg") no-repeat
        center,
      linear-gradient(90deg, #58abe7 0%, #5858e7 42.95%, #ff5238 100%);
    z-index: 99999;
    transition: 0.5s ease;
  }
  .left-sidebar {
    position: fixed;
    left: -150px;
    background-color: #000000;
    z-index: 1111;
    transition: 0.5s;
    height: 450px;
    /* overflow-y: scroll; */
  }
  .sidemenu-com {
    overflow-y: scroll;
    height: 400px;
}
  .sidebar-icon-block .sidebar-icon {
    width: 100%;
  }
  .right-content-block {
    width: 100%;
    padding: 0;
  }
  .left-sidebar.sidebar-toggle {
    left: 0px;
  }
  .event-sec.service-request ul li {
    width: 100%;
  }
  .service-request.event-sec ul {
    margin-bottom: 50px;
  }
  /*vendor profile*/
  .vendor-photography .vendor-event-carousel .owl-nav {
    top: -12px;
  }
  /*Pagefavourite-vendor*/
  .favourite-vendor.sidebar-content-wr {
    text-align: center;
  }
  .favourite-vendor.sidebar-content-wr .fav-vendors-block {
    text-align: left;
  }
  .fav-vendor-pagination nav.data-pagination {
    text-align: center;
  }
  .vendor-profile-sec .right-content-block {
    margin-top: 24px;
  }
  /* create event*/
  .event-form input {
    padding: 5px 8px;
  }
  .service-request-event {
    margin-top: 45px;
  }
  select-event .common-label {
    padding: 0px 0px 18px 0px;
  }
  .service-request-event h4,
  .form-container.event-form h4 {
    margin-bottom: 35px;
  }
  /*consumer*/
  .jason-profile-block {
    width: 100%;
  }
  .security-setting {
    margin: 30px auto 0px;
    display: block;
  }
  .jason-profile-block,
  .security-setting {
    display: block;
    width: 100%;
  }
  .security-setting h6 {
    margin-bottom: 10px;
  }
  .member-since {
    margin-top: 25px;
  }
  .setting-inner-card.premium-card .btn {
    padding: 11px 41px 11px 41px;
  }
  .setting-inner-card.basic-card .your-plan {
    bottom: -10px;
  }
  .right-content-block.chats-outer-block {
    width: 100%;
  }
  /*Analytics Review*/
  .analytics-tabber-list-block.tabber-list-blocks {
    text-align: center;
  }
  .analytics-tabber-list {
    text-align: left;
  }
  /*Vendor Consumer*/
  .vendor-child-friendly-block ul li {
    width: 40%;
    margin: 0 0 20px 30px;
  }
  .vendor-child-friendly-block ul li:nth-child(2n + 1) {
    margin-left: 0;
    padding-left: 0;
  }
  .vendor-child-friendly-block {
    padding: 24px 20px 0;
  }
  .area-operates-heading h3 {
    font-size: 22px;
    line-height: 27px;
  }
  .area-operates-list ul li {
    margin: 10px 0 0 0;
    width: 50%;
  }
  .area-operates-list ul li:first-child {
    margin: 0;
  }
  .popup-content {
    width: 590px;
    height: 330px;
  }
  .popup-content iframe {
    height: 330px !important;
  }
  .chat-note-text {
    bottom: -90px;
  }
  .sidebar-content-wr.recent-review-wr.chat-sidebar-content {
    padding-bottom: 120px;
  }
  .reviewer-block {
    padding: 13px 10px;
  }
  .reviewer-name-wr .reviewer-block .reviewer-details {
    padding-left: 10px;
  }
  /*Vendor profile New*/
  .upload-profile-section .avatar-section {
    max-width: 100%;
    text-align: center;
  }
  .profile-cover-section {
    text-align: center;
  }
  /*Event Listing*/
  .event-listing-select {
    width: 26%;
  }
  .event-listing-search {
    width: 38%;
  }
  .event-listing-type ul li {
    padding-left: 20px;
    margin-left: 20px;
  }
  .event-listing-budget-inner {
    width: 100%;
    margin: 10px 0 0 0;
  }
  .event-listing-budget-inner:first-child {
    margin: 0;
  }
  .event-listing-service-head {
    width: 100%;
  }
  .event-listing-service-list {
    width: 100%;
    margin-top: 0px;
  }
  .event-listing-service-list ul li {
    margin-bottom: 10px;
  }
  /*Event Listing Details*/
  .event-listing-details-wrapper .event-listing-type {
    width: 100%;
  }
  .event-listing-details-wrapper .event-listing-date {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
  .review-by-vendors-block {
    padding: 20px 30px;
  }
  .review-by-vendors-wr {
    padding: 20px 0;
  }
  .review-by-vendors-head {
    width: 100%;
    padding: 16px 40px;
  }
  .review-by-vendors-inner {
    height: 300px;
  }
  .popup-close-btn a {
    border: 2px solid #ffffff;
    line-height: 41px;
  }

  .banner-sec {
    max-height: initial;
    height: 50vh;
  }
  .vender-data {
    width: 100%;
  }

  .video-sec video {
    z-index: 1;
    position: relative;
    object-fit: cover;
    min-height: 637px;
    background: unset;
  }
  .pro-vender-section .vender-data {
    z-index: 2;
  }
  .form-container.event-form {
    text-align: start !important;
  }
  .other-detail-form {
    flex-direction: column;
  }
  .other-detail-feild.other-details-alternate.form-group {
    text-align: start;
  }
  .banner-sec {
    height: auto !important;
  }
  .reviewSection .accordion .accordion-button:not(.collapsed)::after {
    top: 30%;
  }
  .reviewSection .accordion .accordion-button::after {
    top: 56%;
    transform: translateY(-50%);
  }
  h5.unreadMessage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chats-submit-btn {
    width: 10%;
  }

  .signup-signin-sec btn {
    padding: 10px 18px;
  }
  a.navbar-brand.desktop-brand {
    max-width: 150px;
    width: 100%;
  }
  .user-review-right .user-review-img {
    display: flex;
  }
  .analytics-tabber-list {
    width: 100% !important;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .analytics-tabber-content {
    padding: 60px 10px;
  }

  

  .reviewer-block.tabber-list {
    display: flex;
  }
  .right-content-block .reviewer-block.tabber-list {
    display: flex;
  }

  .consumer-block .user-review-right {
    padding: 27px 10px !important;
  }
  .vendor-popup-slider-wr {
      padding: 0;
  }
  /* .video-sec::after {
    border-radius: 30px;
  } */
  /* .area-operates-slider-block .area-operates-slider-content .owl-nav {
    text-align: center;
  } */
  .filter-section-inner {
    margin-bottom: 100px !important;
    margin-top: 50px !important;
  }
  .home-filter-section {
    margin-bottom: 30px !important;
    margin-top: 40px !important;
  }
} /*650*/
@media only screen and (max-width: 649px) {
  /* .video-sec video {
    object-fit: contain;
    min-height: auto;
  } */
  .btn.btn-profile.top-btn{
    margin-top: 10px;
  }
  .owl-carousel .owl-item .vendor-photo-carousel {
    display: block !important;
    width: 100% !important;
    /* height: 99px !important; */
  }
  .fav-vendors-block {
    width: 80%;
  }
  .john-dawn-content ul li {
    text-align: left;
  }
  .john-dawn-content.freq-asked-section.common-inner-container .accordion {
    margin: 20px 0;
  }
  .event-sec.vendor-category ul li {
    width: 100%;
  }
  .event-sec.service-type ul {
    flex-direction: column;
    gap: 15px;
  }
  .common-description p.paragraph {
    font-size: 17px;
  }
  /*auction-vendor Page*/
  .suggested-your-bid-content:nth-child(2) p {
    width: 30%;
  }
  .next-auction-content h6 {
    margin-left: 0px;
  }
  .other-detail-feild {
    width: 45%;
  }
  .other-detail-feild {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
  .other-detail-feild:first-child {
    margin-top: 0px;
  }
  .other-detail-feild label {
    font-size: 16px;
  }
  .service-request .documents-downloads {
    min-height: 150px;
  }
  .other-detail-form {
    text-align: center;
  }
  .other-detail-feild.other-details-alternate:last-child {
    margin-top: 20px;
  }
  .reviewer-name-wr {
    width: 90%;
  }
  .user-review-img > figure {
    width: 13%;
  }
  .user-review-img .reviewer-review {
    width: 85%;
    padding: 0 0 0 5px;
  }
  .reviewer-review-top p {
    font-size: 16px;
    line-height: 26px;
  }
  .other-detail-feild span {
    padding: 14px 0 13px 18px;
  }
  .other-detail-feild input {
    padding: 10.5px 20px;
  }
  /*setting Page*/
  .setting-sec {
    margin-bottom: 20px;
  }
  .setting-inner-card.basic-card .your-plan {
    bottom: -8px;
  }
  .setting-inner-card {
    padding: 28px 45px 38px 45px;
  }
  .setting-cards .setting-inner-card p {
    line-height: 25px;
  }
  /*create event*/
  .create-new-event-sec .event-sec ul {
    flex-wrap: wrap;
    gap: 4px;
  }
  /*Personal Information*/
  .comn-form-feild {
    width: 100%;
    margin: 10px 0px 0 0;
  }
  .comn-form-feild label {
    background-color: transparent;
    padding-bottom: 5px;
  }
  .personal-information-form {
    margin-top: 0;
  }
  .information-form-save-btn {
    margin-top: 20px;
  }
  /*Edit Events*/
  .edit-event-content .other-detail-feild {
    text-align: left;
  }
  .event-sec.edit-service-other-details {
    padding: 15px 0;
  }
  .event-sec.edit-service-other-details ul {
    flex-direction: column;
  }
  .event-sec.edit-service-other-details .checkboxbtn {
    margin: 0;
  }
  /*Consumer Details*/
  .event-details-block {
    margin-top: 50px;
  }
  /*Recent Review*/
  .recent-review-search input {
    padding: 8px 40px;
  }
  .recent-review-content-block .reviewer-details h3 {
    font-size: 13px;
    line-height: 16px;
  }
  .recent-review-content-block .user-review-img .reviewer-review {
    width: 85%;
  }
  /*Analytics Review*/
  .analytics-tabber-content {
    padding: 62px 20px;
  }
  .analytics-tabber-content figure {
    width: 890px;
  }
  .analytics-tabber-content figure figcaption p {
    margin-left: 37px;
    font-size: 20px;
    line-height: 28px;
  }
  .analytics-tabber-inner {
    overflow-x: scroll;
  }
  .popup-content {
    width: 520px;
    height: 294px;
  }
  .popup-content iframe {
    height: 294px !important;
  }
  .chats-textarea-block {
    bottom: 30px;
  }
  .user-chat-bottom {
    position: unset;
  }
  /*Vendor Profile New*/
  .event-categories-heading {
    width: 100%;
  }
  .categories-accordion-item:first-child {
    margin-top: 0;
  }
  .accordion-btn-for-update {
    width: 100%;
    text-align: left;
  }
  .accordion-btn-for-update a {
    margin-top: 30px;
  }
  /*Event Listing*/
  .event-listing-cat-select,
  .event-listing-location-select {
    margin-left: 0;
  }
  .event-listing-search {
    width: 36%;
    margin: 0 6px;
  }
  .event-listing-type-date {
    padding: 20px 25px;
  }
  .event-listing-type {
    width: 100%;
  }
  .event-listing-date {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
  .event-listing-service-list ul li {
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0;
  }
  .event-listing-service-list ul li::after {
    display: none;
  }
  /* .event-listing-service-list ul li:first-child {
    margin: 0;
  } */
  .event-listing-select {
    width: 30%;
  }
  /*Event Listing Details*/
  .event-listing-wrapper.event-listing-details-wrapper {
    padding-top: 0;
  }
} /*568*/
@media only screen and (max-width: 567px) {
  .draft-btn.next-btn-sec .btn{
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .draft-btn .vendor-preview-block{
    display: flex;
    vertical-align: middle;
    justify-content: center;
  }
  .categories-accordion .accordion-body li {
    width: 100% !important;
  }
  .vendor-popup-slider-content {
    padding: 0;
  }
  .vendor-popup-slider-wr .landscape-reels {
    padding: 0;
  }
  
  .left-sidebar {
    width: 20%;
  }
  .fav-vendors-block {
    width: 90%;
  }
  .right-top-text h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .fav-vendors-block {
    width: 100%;
  }
  .sidebar-content-wr {
    padding: 30px 0 15px 0;
  }
  /*setting page*/
  .setting-inner-card {
    width: 100%;
    display: block;
  }
  .setting-inner-card.premium-card {
    margin: 50px 0px 30px 0px;
  }
  .setting-inner-card h4 {
    padding-bottom: 8px;
  }
  .setting-inner-card.premium-card p,
  .setting-cards .setting-inner-card p {
    margin-bottom: 10px;
  }
  /*create event*/
  .vendor-profile-sec .right-content-block,
  .create-new-event-sec .right-content-block {
    width: 100%;
  }
  .post-event-btn {
    margin: 30px 0px;
  }
  .service-request-event {
    margin-top: 50px;
  }
  .service-request-event h4,
  .form-container.event-form h4 {
    margin-bottom: 30px;
  }
  .create-new-event-sec .event-sec ul {
    gap: 20px;
  }
  /*vendor profile*/
  .sidebar-content-wr {
    padding: 30px 0 15px 0;
    z-index: 1;
  }
  .john-dawn-content .event-budget-block ul {
    padding: 0px 20px 0px 0px;
  }
  /*auction-vendor page*/
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 8px 30px;
  }
  /*User Profile*/
  .user-review-left,
  .recent-review-content-block .user-review-left {
    width: 100%;
  }
  .user-review-right {
    width: 100% !important;
    padding: 30px 0 0 0 !important;
    margin-left: 0 !important;
  }
  .user-review-left,
  .recent-review-content-block .user-review-left {
    border: unset;
  }
  :root {
    scroll-behavior: unset;
  }
  .right-content-block {
    padding-left: 0;
  }
  .user-review-img .reviewer-review {
    width: 100%;
    padding: 15px 0 0 0px;
  }
  .service-request-event {
    margin-top: 30px;
  }
  /*Consumer Page*/
  .select-event .common-label {
    margin-bottom: 10px;
    padding: 0px 0px 18px 0px;
  }
  .other-details .common-label {
    margin-bottom: 30px;
  }
  .sidebar-content-wr {
    padding: 30px 0 40px 0;
  }
  /*Edit Events*/
  .event-sec-edit-event {
    margin-bottom: 25px;
  }
  .event-sec.edit-service-other-details ul {
    margin: 0px 0 0 10px;
  }
  .edit-event-content .other-details.event-budget-block.photography-details {
    margin-top: 30px;
  }
  .events-basic-details {
    margin-top: 35px;
  }
  .sidebar-content-wr.edit-events-sidebar {
    padding-bottom: 50px;
  }
  .edit-event-btn {
    position: unset;
  }
  .information-form-top-heading {
    margin-top: 20px;
  }
  /*Consumer Details*/
  .event-details-heading h3 {
    font-size: 20px;
    line-height: 27px;
  }
  .event-details-table {
    margin: 15px auto 0;
  }
  /*Recent Review*/
  .recent-review-content-block .user-review-right {
    width: 100%;
    margin-left: 0;
    padding: 25px 10px;
  }
  .reviewer-block.tabber-list.hover-active::after {
    display: none;
  }
  /*Header Popup*/
  .notification-popup-wr,
  .report-popup-wr {
    right: -12px;
    width: 320px;
  }
  .hover-report-icon {
    opacity: 1;
    pointer-events: all;
  }
  .right-content-block.chats-outer-block {
    padding-bottom: 100px;
  }
  /*Analytics Review*/
  .analytics-tabber-list {
    width: 47%;
    margin: 0 0 40px 20px;
  }
  /*Vendor Consumer*/
  .vendor-total-views-block {
    margin-left: 70px;
  }
  .vendor-child-friendly-block {
    padding: 24px 20px;
  }
  .vendor-child-friendly-block ul li {
    margin: 20px 0 0 0;
    width: 100%;
    padding-left: 0
  }
  .vendor-child-friendly-block ul li:first-child {
    margin-top: 0;
  }
  .area-operates-list ul li {
    width: 100%;
  }
  /*.area-operates-slider-block .area-operates-slider-content {
        width: 395px !important;
        height: 285px !important;
    }*/
  .vendor-consumer-social-links a {
    margin-left: 35px;
  }
  .vendor-popup-slider-content img {
    height: 250px;
  }
  .vendor-popup-slider-content figure a {
    width: 76px;
    height: 76px;
    line-height: 70px;
  }
  .vendor-popup-slider-content figure a::before {
    left: -9px;
    top: -9px;
    width: 94px;
    height: 94px;
  }
  .vendor-popup-slider-content figure a::after {
    width: 119.92px;
    height: 119.92px;
    left: -21px;
    top: -20px;
  }
  .popup-content {
    width: 460px;
    height: 260px;
  }
  .popup-content iframe {
    height: 260px !important;
  }
  .recent-review-left-inner {
    height: 500px;
  }
  .sidebar-content-wr.recent-review-wr.chat-sidebar-content {
    padding-bottom: 50px;
  }
  .user-chat-bottom {
    padding: 35px 0 80px;
  }
  .user-chat-bottom-inner {
    height: 400px;
  }
  .chats-outer-block .user-review-right {
    min-height: 700px;
  }
  /*Vendor Profile New*/
  .vendor-profile-top-form-name .form-group {
    width: 100%;
    margin-left: 0;
  }
  .vendor-profile-wrapper .categories-accordion-head {
    padding: 20px 25px;
  }
  .vendor-profile-wrapper .categories-accordion-head::after,
  .vendor-profile-wrapper .categories-accordion-head::before {
    width: 30px;
    height: 30px;
  }
  .categories-accordion-item {
    margin-top: 15px;
  }
  .accordion-btn-for-update {
    margin-top: auto;
  }
  .promo-video-sec .col-md-5.col-12:first-child {
    width: 274px;
    height: 240px;
    background-color: #030a1c;
  }
  .promo-video-sec .col-md-5.col-12:nth-child(2) {
    width: 130px;
  }
  .reel-description-block {
    margin: 30px 0 60px 0;
  }
  .vendor-links-sec {
    margin: 60px 0 50px;
  }
  .next-btn-sec {
    margin-bottom: 45px;
  }
  .promo-video-sec .col-md-5.col-12:first-child {
    width: 100%;
  }
  .promo-video-sec .col-md-5.col-12:nth-child(2) {
    margin: 20px 0 0 0;
  }
  /*Event Listing*/
  .event-listing-select,
  .event-listing-select select,
  .event-listing-search {
    width: 100%;
  }
  .event-brief-description-box {
    padding: 20px 25px 0;
  }
  .event-listing-search {
    margin: 15px 0;
  }
  .event-listing-select::after {
    right: 20%;
  }
  .event-listing-top-search select {
    /* background: url(../img/select_icon.svg) no-repeat 90% 50%, url(../img/listing_select_bg.svg) no-repeat 5% 50% rgba(0, 0, 0, 0.4); */
  }
  .event-listing-location-select.event-listing-select select {
    /* background: url(../img/select_icon.svg) no-repeat 90% 50%, url(../img/location_compass_icon.svg) no-repeat 5% 50% rgba(0, 0, 0, 0.4); */
  }
  .event-listing-top-search select {
    padding: 7px 50px 7px 50px;
  }
  /*Event Listing Details*/
  .event-listing-details-wrapper .event-listing-date-inner {
    margin: 10px 0 0 0;
  }
  .event-listing-details-wrapper .event-listing-date-inner:first-child {
    margin: 0;
  }
  .event-listing-consumer-inner {
    margin: 10px 0 0 0;
  }
  .event-listing-consumer-inner:first-child {
    margin: 0;
  }
  .popup-close-btn a {
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 22px;
  }
  .review-by-vendors-head {
    padding: 10px 30px;
  }
  /*Privacy*/
  .privacy-policy-text-block h3 {
    margin: 35px 0 10px;
    font-size: 20px;
    line-height: 30px;
  }
  .privacy-policy-text-block p {
    margin: 15px 0 0 0;
    line-height: 28px;
  }
  .privacy-policy-banner-img img {
    height: 250px;
  }
  .privacy-policy-heading h1,
  .maintenance-heading h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .maintenance-logo {
    margin-top: 30px;
  }
} /*480*/
@media only screen and (max-width: 479px) {
  .loader-item.vendor-update p {
    font-size: 20px;
  }
  .loader-item .sl-loader {
    margin-top: 20em !important;
  }
  .accordion-btn-for-update .btn {
      height: 100% !important;
  }
  .header {
    position: relative;
    z-index: auto;
  }
  nav.navbar .container {
    padding: 0;
  }
  .right-content-block {
    padding-left: 0px;
  }
  .sidebar-icon ul li a {
    width: 42px;
    height: 42px;
  }
  /*setting page*/

  /*create event*/
  .form-container.event-form {
    margin-top: 40px;
  }
  /*vendore profile*/
  .john-dawn-content .event-budget-block ul {
    padding: 0px 0px 0px 0px;
  }
  .vendor-profile-sec .common-description .paragraph {
    padding: 30px 12px 30px 15px;
  }
  .vendor-profile-sec .event-sec ul li .checkboxbtn label {
    padding: 1px 0 0 30px;
  }
  .john-description.common-description h4 {
    margin: 0px 0px 18px 0px;
  }
  /*favourite-vendor*/
  .favourite-vendor.sidebar-content-wr .right-content-block {
    width: 95%;
    margin: auto;
  }
  /*auction-vendor page*/
  .suggested-your-bid-content h4 {
    width: 55%;
  }
  .suggested-your-bid-content:nth-child(2) p {
    width: 22%;
  }
  .suggested-your-bid-content a {
    width: 40px;
    height: 40px;
  }
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 8px 17px;
  }
  .next-auction-content {
    margin: 10px 0% 0px 0px;
    display: block;
  }
  .next-auction {
    text-align: center;
  }
  .sidebar-content-wr.consumer-block .right-top-text h3 {
    text-align: center;
  }
  .security-setting h6 {
    text-align: center;
  }
  .security-setting {
    margin: 30px auto 20px;
  }
  .setting-inner-card.premium-card {
    margin: 50px 0px 30px 0px;
  }
  .setting-inner-card.premium-card .btn {
    padding: 11px 41px 9px 41px;
  }
  .auction-accordion-block .accordion-button h4,
  .auction-accordion-block .accordion-item h4,
  .page-heading h4 {
    letter-spacing: 2px;
  }
  .setting-list {
    margin: 25px auto 0px;
  }
  .post-event-btn .btn {
    padding: 11px 66px;
  }
  .analytics-tabber-list {
    width: 100%;
    margin: 50px 0 0 0;
  }
  .analytics-tabber-list:first-child {
    margin: 0;
  }
  .vendor-consumer-img-name ul li {
    font-size: 14px;
    line-height: 17px;
  }
  .vendor-consumer-img-name figure {
    width: 190px;
    height: 188px;
    /* padding: 8px; */
  }
  .vendor-review-send-btn {
    padding: 20px 0 0;
  }
  /*.area-operates-slider-block .area-operates-slider-content {
        width: 335px !important;
        height: 225px !important;
    }*/
  .vendor-banner img {
    height: 200px;
  }
  .popup-content {
    width: 400px;
    height: 225px;
  }
  .popup-content iframe {
    height: 225px !important;
  }
  /*Vendor Profile New*/
  .vendor-profile-wrapper .guess-section {
    width: 100%;
  }
  .vendor-profile-wrapper .guess-section .min-max-sec {
    margin-left: 0;
    max-width: 100%;
  }
  .vendor-profile-wrapper .categories-accordion-venue-checkbox {
    width: 100%;
  }
  /*Event Listing*/
  .event-listing-wrapper {
    padding: 40px 0 70px;
  }
  .find-options-text {
    margin-top: 35px;
  }
  .find-options-text span.scroll-btn {
    display: block;
    margin: 10px 0 10px;
  }
  .review-by-vendors-popup {
    width: 94%;
  }
  .popup-close-btn {
    right: 0;
    top: -20px;
  }
  .review-by-vendors-block {
    padding: 20px 15px;
  }
  .review-by-vendors-head {
    padding: 10px 15px;
  }
  /*Privacy*/
  .privacy-policy-heading h1,
  .maintenance-heading h1,
  .privacy-policy-text-block h3 {
    letter-spacing: 2px;
  }
  .privacy-policy-banner-img img {
    height: 220px;
  }
} /*414*/
@media only screen and (max-width: 413px) {
  .chat-event-name{
    text-align: center;
  }
  .user-name-rating {
    align-items: center;
    flex-direction: column;
  }
  .right-top-text h3 {
    font-size: 18px;
    line-height: 23px;
  }
  .fav-vendors-block {
    padding: 14px 19px;
  }
  .learn-more-btn,
  .heart-btn {
    width: 100%;
    text-align: left;
  }
  .heart-btn {
    margin-top: 10px;
  }
  /*vendor profile*/
  .vendor-photography .vendor-event-carousel .owl-nav button.owl-next {
    right: 0px;
  }
  .vendor-photography .vendor-event-carousel .owl-nav button.owl-prev {
    left: 12px;
  }
  .vendor-photography {
    padding: 45px 0px 0px 0px;
  }
  .auction-accordion-block .accordion-tabs-blocks ul li {
    padding: 8px 17px;
    width: 100%;
    margin: 10px 0px 0px 0px;
  }
  .auction-accordion-block .accordion-tabs-blocks ul li:first-child {
    margin-top: 0px;
  }
  .right-top-text h3 {
    text-align: center;
  }
  .history-bid-box p {
    margin-top: 10px;
  }
  .btn {
    padding: 8px 15px;
    height: 35px;
    font-size: 15px;
  }
  /*ConsumerPage */
  .service-request {
    margin-top: 40px;
  }
  .auction-accordion-block .accordion-item h4 {
    line-height: 24px;
  }
  .sidebar-content-wr {
    padding: 30px 0 20px 0;
  }
  .next-auction-content {
    margin: 5px 0% 0px 0px;
  }
  .next-auction-content h6 {
    font-size: 16px;
  }
  .change-password-btn.btn {
    padding: 4px 15px !important;
  }
  .jason-profile-block {
    padding: 65px 22px 20px 22px;
  }
  .create-new-event-sec .event-sec ul {
    gap: 10px;
  }
  .create-new-event-sec .event-sec ul li {
    width: 100%;
  }
  .sidebar-content-wr.personal-information-sidebar {
    padding-bottom: 50px;
  }
  /*Consumer Details*/
  .event-details-heading h3 {
    font-size: 18px;
    line-height: 25px;
  }
  /*Vendor Consumer*/
  .vendor-consumer-img-name h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .vendor-consumer-img-name ul li::after {
    width: 7px;
    height: 7px;
  }
  .vendor-consumer-img-name ul li {
    margin-left: 3px;
    padding-left: 15px;
  }
  .area-operates-heading h3 {
    font-size: 21px;
    line-height: 26px;
  }
  .vendor-popup-slider-content img {
    height: 200px;
  }
  .vendor-popup-slider-content figure a {
    width: 66px;
    height: 66px;
    line-height: 60px;
  }
  .vendor-popup-slider-content figure a::before {
    left: -9px;
    top: -9px;
    width: 84px;
    height: 84px;
  }
  .vendor-popup-slider-content figure a::after {
    width: 109.92px;
    height: 109.92px;
    left: -22px;
    top: -22px;
  }
  .popup-content {
    width: 360px;
    height: 200px;
  }
  .popup-content iframe {
    height: 200px !important;
  }
  /*Event- Listing*/
  .event-listing-type-date {
    padding: 20px 15px;
  }
  .event-listing-date p:last-child {
    margin: 10px 0 0 0;
    width: 100%;
  }
  /*Event- Listing Details*/
  .event-listing-details-wrapper .event-listing-date-inner {
    margin: 25px 0 0 0;
  }
  /*Privacy*/
  .privacy-policy-heading h1,
  .maintenance-heading h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .maintenance-logo {
    margin-top: 20px;
  }
} /*375*/
@media only screen and (max-width: 374px) {
  .sidebar-icon ul li a {
    width: 35px;
    height: 35px;
  }
  .sidebar-icon ul li a img {
    width: 60%;
  }
  .right-content-block {
    width: 78%;
  }
  .right-content-block.vendor-profile-wrapper {
    width: 100%;
  }
  .musician-location-block h6:first-child {
    width: 65%;
  }
  .musician-location-block h6:nth-child(2) {
    width: 33%;
  }
  .fav-vendor-pagination .data-pagination a {
    width: 26px;
    height: 26px;
  }
  .fav-vendor-pagination a.arrow i {
    font-size: 14px;
  }
  /*create event*/
  .sidebar-content-wr .right-content-block {
    width: 100%;
  }
  /*favourite-vendor*/
  .favourite-vendor.sidebar-content-wr .right-content-block {
    width: 100%;
  }
  .suggested-your-bid-content:first-child p {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .suggested-your-bid-content:nth-child(2) {
    text-align: center;
  }
  .suggested-your-bid-content h4 {
    width: 67%;
  }
  .suggested-your-bid-content:first-child p {
    width: 31%;
  }
  .suggested-your-bid-content a {
    margin-top: 20px;
  }
  .btn {
    padding: 8px 13px !important;
    height: 35px !important;
    font-size: 15px !important;
  }
  nav.navbar .container {
    padding: 0;
  }
  .setting-inner-card {
    padding: 28px 15px 38px 15px;
  }
  .setting-inner-card.premium-card .btn {
    padding: 10px 43px 27px 43px !important;
  }
  .notification-popup-wr,
  .report-popup-wr  {
    /* position: absolute; */
    right: -18px !important;
    width: 295px;
}
  .report-popup-wr
  {
    position: initial !important;
    right: 0px !important;
    left: 0 !important;
    margin: 0 auto !important;
  }

  .tippy-tooltip.tippy-tooltip--regular.dark-theme.tippy-notransition .report-popup-wr
  {

    position: initial !important;
    right: 0px !important;
    left: 0 !important;
    margin: 0 auto !important;
  }
} /*320*/
@media only screen and (min-width: 2100px) {
  body {
    background-size: cover;
  }
  .vendor-event-carousel {
    max-width: 1266px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1200px) {
  .owl-carousel .owl-item .vendor-photo-carousel{
    display: block !important;
    width: 100% !important;
    height: 300px !important;
  }
  .navbar-header {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  /*.header.sticky-header .navigation { margin: 15px 0; }
.sticky-header .navbar-brand { width: 200px; }
.sticky-header .search-form { top: 55px; }*/
}
@media only screen and (min-width: 1300px) {
  /* .area-operates-slider-block {
    margin: 44px 0px;
    margin-left: 300px;
    
  } */
  .owl-carousel .owl-item .vendor-photo-carousel {
    display: block !important;
    width: 100% !important;
    height: 300px !important;
  }
  .desh-headerright .navbar-brand {
    display: none;
  }
}
@media only screen and (min-width: 1700px) {
  .banner.small-banner .banner-caption {
    margin-top: 97px;
  }
  .checkout-section .small-container {
    min-width: 1570px;
  }
}
@media only screen and (min-width: 2000px) {
  #wrapper {
    margin: 0 auto;
    width: 100%;
    float: none;
    max-width: 2000px;
  }
}
@media only screen and (max-width: 1860px) {
  /* .container { min-width: 1500px; width: 100%; } */
  .footer-link-box a {
    width: 114px;
  }
  .social-media li {
    margin: 0 20px 0 0;
  }
  .feature-tab {
    margin-top: 0;
  }
  .feature-thumb-big {
    height: 328px;
  }
  .swiper-slider1 {
    height: 500px;
  }
  .top-trending {
    overflow: hidden;
  }
  .featuredbox-left {
    height: 747px;
  }
  #wrapper {
    overflow-x: hidden;
  }
  .dashboard-page .card p {
    font-size: 30px;
  }
  .dashboard-page .card-icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  .page-header__img2 {
    width: 400px;
  }
  .page-header__img4 {
    width: 320px;
  }
  .page-header__img4 {
    width: 250px;
  }
}
@media only screen and (max-width: 1700px) {
  .banner-caption h1 {
    margin: 0 0 10px;
  }
  h2,
  .h2 {
    font-size: 32px;
    line-height: 1.5;
  }
  .heading h2 {
    font-size: 32px;
    line-height: 38px;
  }
  .slider-short-detail .featured-text {
    padding: 20px 30px;
  }
  .header-margin {
    height: 150px;
  }
  .container {
    min-width: 1356px;
  }
  .footer-link-box li {
    margin: 5px 8px 15px 0px;
  }
  .footer-link-box a {
    width: 106px;
  }
  .feature-thumb-big {
    height: 290px;
  }
  .feature-thumb-small {
    height: 250px;
  }
  .featuredbox-left {
    height: 674px;
  }
  .swiper-slider1 {
    height: 450px;
  }
  .custom-tab .nav-tabs li a {
    min-width: 190px;
  }
  .main-menu {
    padding: 0 0 0 40px;
  }
  .card-header h2,
  .detail-leftbar h2,
  .heading-big h2 {
    font-size: 40px;
    letter-spacing: 3.5px;
  }
  .about-right svg {
    max-width: 100%;
    height: 450px;
  }
  #dash_wrapper {
    padding: 0 0 0 300px;
  }
  #sidebar-wrapper {
    width: 300px;
  }
  .dashboard-page .card h3 {
    font-size: 22px;
  }
  .dashboard-page .card-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .dashboard-page .card p {
    font-size: 24px;
  }
  .table thead th {
    font-size: 17px;
    padding: 22px 10px 22px;
  }
  .table td,
  .table th {
    padding: 20px 10px;
    font-size: 16px;
  }
  .table td:first-child,
  .table th:first-child {
    padding-left: 30px;
  }
  .page-header h1,
  .page-header h2 {
    font-size: 24px;
  }
  .dashboard-rightbar h2 {
    font-size: 22px;
  }
  .custom-form.welcome-screen {
    padding: 50px 40px 50px;
  }
  /* .custom-form { width: 620px; } */
  .welcome-screen h1 {
    font-size: 40px;
    margin: 0 0 20px;
    line-height: 54px;
  }
  /* .custom-form p { font-size: 22px; line-height: 30px; } */
  .login-btn-group {
    margin: 40px auto 0;
    max-width: 470px;
  }
  .signup-page {
    padding: 22px 0 100px;
    min-height: calc(100vh - 160px);
  }
  .custom-form {
    margin: 0 auto 20px;
    padding: 40px 40px;
  }
  .form-footer {
    margin-top: 30px;
  }
  h1,
  .h1 {
    font-size: 32px;
    margin: 0 0 15px;
  }
  .loginForm {
    margin: 25px 0 0;
  }
  .custom-form.signup_screen {
    margin-top: 0;
    width: 800px;
    padding: 60px 100px;
  }
  .header .btn {
    min-width: 150px !important;
  }
  .header-right {
    margin: 0 0 35px;
  }
  .btn.btn-large {
    font-size: 22px;
    height: 60px;
    line-height: 38px;
  }
  .card--inner {
    padding: 40px 15px 20px 35px;
    min-height: 220px;
    background-size: 200px;
  }
  .appoinment-content .page-header h3 {
    font-size: 22px;
  }
  .appoinment-content .page-header {
    padding: 60px 35px 56px;
  }
  .page-header__img3 {
    width: 330px;
  }
}
@media only screen and (max-width: 1500px) {
  .next-btn-sec .btn {
    min-width: 240px;
    margin-right: 10px;
  }
  .text__left,
  .text__right {
    max-width: 630px;
    padding: 0 20px;
  }
  .accordion .card-header a {
    font-size: 30px;
    padding: 13px 10px 13px 100px;
  }
  .accordion .numbering {
    line-height: 80px;
  }
  .slider-short-detail {
    left: 200px;
    right: 200px;
  }
  .container {
    min-width: 1200px;
  }
  .swiper-slider1 {
    height: 400px;
  }
  .swiper-button-next {
    background-position: -50px 0;
    width: 50px;
    height: 60px;
    right: 55px;
    background-size: 100px auto;
  }
  .swiper-button-prev {
    background-position: -50px 0;
    width: 50px;
    height: 60px;
    left: 60px;
    background-size: 100px auto;
  }
  .custom-tab .nav-tabs li a {
    min-width: 160px;
  }
  .heading {
    margin: 0 0 50px;
  }
  .owl-carousel .owl-nav .owl-next {
    background-position: 0 0;
    width: 50px;
    height: 60px;
    right: -70px;
    background-size: 100px auto;
  }
  .owl-carousel .owl-nav .owl-prev {
    background-position: -50px 0;
    width: 50px;
    height: 60px;
    left: -70px;
    background-size: 100px auto;
  }
  .owl-carousel .owl-nav .owl-prev:hover {
    background-position: 1px 0;
    background-size: 100px auto;
  }
  .owl-carousel .owl-nav .owl-next:hover {
    background-position: -50px 0;
    background-size: 100px auto;
  }
  .skew-btn {
    width: 210px;
  }
  .footer-link-box li {
    margin: 5px 8px 5px 0px;
  }
  .footer-link-box a {
    width: 90px;
    font-size: 16px;
  }
  .featuredbox-right h3,
  .heading-2 h3 {
    font-size: 21px;
    margin: 0 0 25px;
  }
  .feature-thumb-big {
    height: 255px;
  }
  .feature-thumb-small {
    height: 215px;
  }
  .footer {
    margin: 100px 0 0;
  }
  body {
    font-size: 17px;
  }
  .publisher-disc-box li span {
    width: 45%;
  }
  .publisher-disc-box li .publisher-detail {
    width: 55%;
  }
  .btn-group1 .btn.custom-btn {
    width: 160px;
    height: 60px;
    font-size: 15px;
    line-height: 39px;
    padding: 10px 15px;
    letter-spacing: 1px;
    background-size: 160px;
  }
  .featuredbox-left {
    height: 592px;
  }
  .form-control {
    height: 45px;
    padding: 3px 15px;
  }
  select.form-control:not([size]):not([multiple]) {
    height: 45px;
  }
  .contact-right {
    padding: 0;
  }
  .about-right svg {
    height: 420px;
  }
  .carousel-more-game .star-rating,
  .rate--now .star-rating {
    font-size: 25px;
  }
  .carousel-more-game .info-box-outer {
    margin: 0 0 15px;
  }
  .rate--now {
    margin: 40px 0 40px;
  }
  .sidebar-nav li a {
    padding: 17px 20px 17px 70px;
  }
  .sidebar-icon {
    left: 22px;
  }
  .dashboard-page .card-icon {
    width: 60px;
    height: 60px;
    line-height: 55px;
    padding: 0 10px;
  }
  .dashboard-page .card h3 {
    font-size: 20px;
  }
  .dashboard-page .card p {
    font-size: 19px;
  }
  .dashboard-page .card small {
    font-size: 15px;
  }
  .card .table td,
  .card .table th {
    font-size: 14px;
  }
  .card-header h4 {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.5px;
  }
  .card .table th:first-child,
  .card .table td:first-child {
    width: 15%;
  }
  .social-media li {
    margin: 0 12px 0 0;
  }
  .btn.blue-btn {
    width: 270px;
    height: 80px;
    font-size: 20px;
    line-height: 60px;
    padding: 10px 30px;
    background-size: 270px;
  }
  .btn.blue-btn::before {
    background-size: 100%;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -15px;
  }
  .banner-caption h1 {
    font-size: 38px;
    line-height: 1.7;
    letter-spacing: 4.5px;
  }
  .map iframe {
    height: 420px;
  }
  .about-right {
    padding: 65px 0 0 40px;
  }
  .info-short-box h4 {
    font-size: 20px;
    margin: 0 0 12px;
  }
  .card-header h2,
  .detail-leftbar h2,
  .heading-big h2 {
    font-size: 32px;
    letter-spacing: 1.5px;
  }
  .page-header {
    height: 95px;
    line-height: 90px;
  }
  .custom-radiobtn {
    margin: 8px 0;
  }
  .signup-page.subscription-container .container {
    min-width: 1320px;
  }
  .membershipForm {
    width: 1300px;
    padding: 100px 122px 75px;
  }
  .middle-section .container,
  .container {
    padding: 0 20px;
  }
  .appoinment-card {
    padding: 14px;
  }
  .select-arrow {
    top: 40px;
  }
  .page-header__img2 {
    width: 280px;
  }
  .time-slot-box .radio-buttons {
    margin: 0 25px 25px 0;
  }
  .calendar-container .ui-datepicker-calendar .ui-state-default {
    width: 26px;
    height: 26px;
  }
  .ui-datepicker-inline {
    padding: 0 5px 5px;
  }
  .calendar-container .ui-datepicker-title {
    margin: 0;
  }
  .calendar-container .ui-datepicker-calendar .ui-state-default,
  .calendar-container .ui-datepicker-calendar th {
    font-size: 13px;
  }
  .calendar-container .ui-datepicker-calendar th {
    padding: 4px;
  }
  .page-header__img3 {
    width: 300px;
  }
  .booked-appoinment h2 {
    font-size: 30px;
  }
  .booked-appoinment p {
    font-size: 18px;
  }
  .main-content-head p {
    font-size: 18px;
  }
  .page-header h3 {
    font-size: 25px;
  }
  .page-header p {
    font-size: 18px;
  }
  .reward-card-inner h4 {
    font-size: 18px;
  }
  .reward-card-inner {
    padding: 15px;
  }
  .last-days {
    font-size: 13px;
    margin-right: 30px;
    top: 18px;
  }
  .page-header__img5,
  .page-header__img6 {
    width: 250px;
    right: 0;
  }
  .page-header__img7 {
    width: 200px;
    right: 0;
  }
  .page-header__img8 {
    width: 250px;
    right: 0;
  }
  .page-header__img {
    width: 250px;
  }
  .bef-aft-box:first-child {
    margin-right: 20px;
  }
  .product-detail .product-name {
    font-size: 16px;
  }
  .product-detail .product-category,
  .product-detail .product-price {
    font-size: 14px;
  }
  .event-budget-block li {
    margin-right: 175px;
  }
  .documents-downloads {
    min-height: 235px;
  }
}
@media only screen and (max-width: 1360px) {
  body {
    font-size: 16px;
  }
  .vendor-consumer-reviews-bottom {
    display: flex;
    align-items: center;
    justify-content: start;
}
  .mb-100 {
    margin-bottom: 60px;
  }
  .heading h4 {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 10px;
  }
  .heading h2 {
    font-size: 26px;
    line-height: 1.5;
  }
  .heading h2::after {
    width: 100px;
    left: 160px;
  }
  .heading h2::before {
    left: -160px;
    width: 100px;
  }
  h1,
  .h1 {
    font-size: 30px;
    letter-spacing: 1px;
  }
  h4,
  .h4 {
    font-size: 20px;
    line-height: 1.54;
  }
  h5,
  .h5 {
    font-size: 18px;
  }
  .card-header h2,
  .detail-leftbar h2,
  .heading-big h2 {
    font-size: 30px;
    letter-spacing: 1px;
  }
  .heading {
    margin: 0 0 40px;
  }
  .footer {
    margin: 80px 0 0;
  }
  .container {
    min-width: 0;
    width: 100%;
    max-width: 1030px;
  }
  .feature-tab li {
    margin: 15px 0;
  }
  .footer-link {
    flex: 0 0 31%;
    max-width: 31%;
    margin-left: 3.5%;
  }
  .skew-btn {
    width: 190px;
  }
  .social-media li {
    margin: 0 15px 0 0;
  }
  .feature-thumb-small {
    height: 160px;
  }
  .feature-thumb-big {
    height: 225px;
  }
  .owl-carousel-video .owl-item.center .item-video {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    margin: 50px 0 0;
  }
  .owl-carousel-video .owl-item {
    padding: 60px 0;
  }
  .banner-caption h1 {
    font-size: 28px;
    line-height: 1.6;
    font-weight: normal;
    letter-spacing: 1.5px;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  }
  .banner-caption {
    bottom: 140px;
  }
  .footer .container {
    padding-left: 15px;
  }
  .footer-right {
    padding-left: 15px;
  }
  .custom-tab .nav-tabs li a {
    min-width: 140px;
  }
  .custom-tab .nav-tabs li a::after {
    background-size: 100% 90%;
  }
  .footer-link-box a {
    width: 85px;
  }
  .social-media li a {
    font-size: 32px;
  }
  .games-promotion {
    margin: 60px 0;
  }
  .smoke-bg-left,
  .smoke-bg-right {
    width: 200px;
    bottom: 30px;
  }
  .feature-section .smoke-bg-left,
  .feature-section .smoke-bg-right {
    bottom: -110px;
  }
  .about-game {
    margin: 40px 0;
  }
  .info-short-box h4 {
    font-size: 19px;
    margin: 0 0 15px;
  }
  .info-short-box {
    padding: 100px 15px 18px 15px;
    min-height: 100px;
  }
  #thumbs .item {
    height: 100px;
    margin: 15px 0 0;
  }
  .system-requirment ul {
    width: 100%;
  }
  .system-requirment ul:nth-child(2) {
    width: 100%;
  }
  .btn-group1 .btn.custom-btn {
    width: 130px;
    height: 50px;
    font-size: 13px;
    background-size: 130px;
    min-width: 0;
    line-height: 30px;
  }
  .skew-btn {
    font-size: 18px;
  }
  .featuredbox-left {
    height: 504px;
  }
  .map iframe {
    height: 400px;
  }
  .form-control {
    height: 50px;
  }
  select.form-control:not([size]):not([multiple]) {
    height: 50px;
  }
  .custom-form h1,
  .main-heading-small h1 {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 1.3;
  }
  .about-section {
    padding: 40px 0;
  }
  .about-right svg {
    height: 350px;
  }
  .signup-page.subscription-container .container {
    min-width: 0;
  }
  .custom-form p {
    font-size: 20px;
    line-height: 1.5;
  }
  .time-slot-box label {
    font-size: 13px;
  }
  .time-slot-box label {
    font-size: 13px;
    line-height: 1.5;
  }
  .profile-detail {
    padding: 30px;
  }
  .profile-detail .form-group.w-9 {
    width: 100%;
  }
  /* new css start */
  .login-right .footerlink {
    font-size: 12px;
    line-height: 14px;
  }
  /* new css End */
  .banner-event-list .search-section {
    min-width: 530px;
  }
  .banner-event-list .common-select-sec {
    min-width: 204px;
  }
  .joshn-weds-inner .event-date-sec {
    width: 37%;
  }
  .joshn-weds-inner h4 {
    width: 24%;
  }
  .videography-block {
    width: 38%;
  }
  .event-budget-block li {
    margin-right: 10%;
  }
  .documents-downloads {
    width: 345px;
  }
  .analytics-tabber-list-block.tabber-list-blocks {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  .analytics-tabber-list {
    width: 48%;
    margin-left: 0;
  }
  .event-listing-type ul li {
    font-size: 19px;
    padding-left: 30px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1299px) {
  .sidemenu-btn {
    display: block;
  }
  /**menu**/
  .collapse.in {
    display: block;
  }
  .navbar-toggle {
    display: block;
  }
  .sidemenu-btn .navbar-toggle .icon-bar {
    background: #ffffff;
  }
  #dash_wrapper {
    padding: 0;
    margin-left: 0;
  }
  #sidebar-wrapper {
    left: -300px;
    padding: 0;
  }
  #dash_wrapper.toggled-wrapper #sidebar-wrapper {
    left: 0;
  }
  #dash_wrapper.toggled-wrapper {
    margin-left: 300px;
  }
  #sidebar-wrapper .navbar-brand {
    display: none;
  }
  .desh-headerleft {
    width: 100%;
    background: #192647;
    padding: 10px 20px;
  }
  .form-group.has-search {
    float: right;
    margin: 0;
  }
  .has-search .form-control {
    height: 44px;
  }
  .has-search .form-control-feedback {
    width: 46px;
    height: 44px;
    line-height: 44px;
  }
  .desh-headerright {
    width: 100%;
    padding: 10px 20px;
    justify-content: space-between;
  }
  .dashboard-header {
    min-height: 0;
    padding: 0;
  }
  .sidebar-nav {
    margin: 0;
    padding: 0;
    max-height: 100vh;
    border: none;
  }
  .toggled-wrapper .sidemenu-btn .navbar-toggle .icon-bar {
    transform: rotate(45deg);
    margin-top: 10px;
  }
  .toggled-wrapper .sidemenu-btn .navbar-toggle .icon-bar:last-child {
    transform: rotate(-45deg);
    margin-top: -16px;
  }
  .toggled-wrapper .sidemenu-btn .navbar-toggle .icon-bar:nth-child(3) {
    opacity: 0;
  }
  .dashboard-content {
    padding: 32px 20px 75px;
  }
  .login-btn-group {
    margin: 35px auto 0;
  }
  .dashboard-header .navbar-brand {
    width: 90px;
    margin-right: 20px;
  }
  .headR1 {
    margin-left: auto;
  }
  .page-header__img3,
  .page-header__img5,
  .page-header__img6 {
    width: 270px;
  }
  .joshn-wedding-popup {
    max-width: 100%;
    width: 94%;
  }
  .joshn-wedding-popup .common-description h4 {
    margin-bottom: 10px;
  }
  .joshn-wedding-popup h4 {
    font-size: 17px;
    line-height: 18px;
  }
  .joshn-wedding-popup .documents-downloads {
    width: 100%;
  }
  .joshn-wedding-popup .event-document {
    width: 35%;
    margin-left: 29px;
  }
  .joshn-wedding-popup {
    max-height: 435px;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 10px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #0b153b;
  }
  ::-webkit-scrollbar-thumb:vertical {
    height: 10x;
    background-color: #0a4c95;
  }
  .analytics-tabber-list {
    width: 48%;
    margin-left: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .search_room-form select.form-control:not([size]):not([multiple]) {
    height: 50px;
  }
  h1,
  .h1 {
    font-size: 26px;
  }
  .card-header h2,
  .detail-leftbar h2,
  .heading-big h2 {
    font-size: 26px;
    letter-spacing: 1px;
  }
  .feature-thumb-big {
    height: 175px;
  }
  .feature-thumb-small {
    height: 140px;
  }
  .container {
    max-width: 870px;
  }
  .feature-tab {
    margin: 0 -10px;
  }
  .feature-tab li {
    margin: 10px 0;
    padding: 0 10px;
  }
  .custom-tab .nav-tabs li a {
    min-width: 100px;
    line-height: 34px;
    padding: 5px 10px;
    height: 50px;
  }
  .skew-btn {
    width: 170px;
    padding: 15px;
  }
  .footer-link-box li {
    margin: 5px 0;
    width: 100%;
  }
  .footer-link-box a {
    width: 100%;
  }
  .footer-logo {
    left: -40px;
    padding: 35px 85px 0 62px;
    margin-bottom: 19px;
    width: 246px;
    background-size: 260px;
  }
  .social-media li a {
    font-size: 30px;
  }
  .swiper-slider1 {
    height: 420px;
  }
  .main-menu li a {
    font-size: 22px;
  }
  .main-menu li {
    margin: 15px 0;
  }
  .more-game-section {
    margin: 40px 0 0;
  }
  .detail-right-sidebar {
    margin: 25px 0 0;
  }
  .featuredbox-left {
    height: 420px;
  }
  .middle-section .smoke-bg-left,
  .middle-section .smoke-bg-right {
    top: 30%;
  }
  .custom-form {
    padding: 0px 15px 0px;
    max-width: 100%;
    min-height: 0;
  }
  .custom-form.signup_screen {
    width: 646px;
    padding: 45px 40px 40px;
  }
  .form-control {
    height: 38px;
  }
  select.form-control:not([size]):not([multiple]) {
    height: 38px;
  }
  .form-group label {
    margin-bottom: 5px;
    font-size: 13px;
  }
  .promo-video-sec .common-label{
    font-size: 14px;
  }
  .custom-form h1,
  .main-heading-small h1 {
    font-size: 30px;
  }
  .about-right {
    padding: 20px 0 0;
  }
  .about-right svg {
    height: 300px;
  }
  .radio-inline .radio-btn {
    min-width: 0;
    font-size: 16px;
  }
  .logged-in ul li a {
    padding: 6px 10px;
  }
  .membershipForm {
    width: 100%;
    padding: 104px 80px;
  }
  .pricingTable .pricing-content li {
    padding: 0 15px 0 35px;
    font-size: 12px;
  }
  .top-header h4 {
    font-size: 16px;
    line-height: 1.3;
  }
  .form-group.has-search {
    width: 300px;
  }
  .app-btn-group .btn {
    margin-bottom: 5px;
  }

  /* new css start */
  .header .btn {
    min-width: 110px;
  }
  .submit-btn {
    width: 115px;
  }
  .banner-event-list .searchandlocation {
    gap: 0px;
  }
  .joshn-weds-inner .event-date-sec {
    width: 30%;
  }
  .joshn-weds-inner {
    padding: 25px 20px 25px 20px;
  }
  .joshn-weds-inner .event-date-sec {
    width: 28%;
    text-align: center;
  }
  .joshn-weds-inner h4 {
    width: 30%;
  }
  .banner-event-list .search-section {
    min-width: 370px;
  }
  .videography-block:before {
    left: -33px;
  }
  .event-budget-block ul {
    padding: 0px 20px 25px 20px;
  }
  .event-budget-block li {
    margin-right: 8%;
  }
  .event-budget-block p {
    margin: 0px 0px 0px 15px;
  }
  .documents-downloads {
    min-height: 268px;
  }
  .documents-downloads {
    width: 100%;
  }
  .event-document {
    width: 34%;
  }
  .banner-inner-block,
  .steve-birthday,
  .nancy-pool {
    margin: 0px auto 80px;
  }
  .recent-review-content-block .right-box {
    display: flex;
    gap: 30px;
  }
  .recent-review-content-block .user-review-left {
    height: auto;
  }
  .page-heading {
    display: flex;
    gap: 10px;
  }
  .event-listing-budget-block {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .event-listing-type ul li {
    font-size: 18px;
    padding-left: 10px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .desktop-view {
    display: none;
  }
  .filter-section-row.tablet-view {
    display: block; 
    display: flex !important;
    flex-direction: column;
  }
  .filter-section-row.tablet-view .search-section {
    min-width: unset;
    max-width: 100%;
  }
  .searchandlocation {
    display: flex;
    gap: 10px;
  }
  .searchandlocation .common-select-sec {
    max-width: 100%;
    min-width: unset;
  }
  .searchandlocation .category-filter {
    width: 100%;
  }
  .searchandlocation .location-filter {
    width: 100%;
  }
  /* .filter-section-row {
    display: none;
  } */
  .banner-event-list .event-service li {
    margin-left: 415px;
  }
  .joshn-wedding-popup .event-document {
    width: 34%;
  }
  .joshn-wedding-popup .event-budget-block li {
    margin-right: 7%;
  }
  .banner-event-list .category-filter select#u-state {
    border: unset;
  }
  .banner-event-list .search-filter .search-section:after,
  .videography-block:before {
    position: unset;
    transform: unset;
  }
  .banner-event-list .searchandlocation {
    margin-top: 20px;
  }

}
@media only screen and (max-width: 991px) {
  /* body{
      background-size: cover !important;
    background-position: 70% !important;
  } */

  .heading h2::before {
    left: -115px;
    width: 60px;
  }
  .heading h2::after {
    width: 60px;
    left: 115px;
  }
  h1,
  .h1 {
    font-size: 22px;
  }
  h2,
  .h2 {
    font-size: 22px;
  }
  .card-header h2,
  .detail-leftbar h2,
  .heading-big h2 {
    font-size: 20px;
  }
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  .custom-tab .nav-tabs li a {
    min-width: 85px;
    line-height: 30px;
    padding: 4px 0;
    height: 44px;
    font-size: 14px;
  }
  .footer .skew-btn {
    background: none;
    transform: skewX(0);
    -webkit-transform: skewX(0);
    -moz-transform: skewX(0);
    -ms-transform: skewX(0);
    position: static;
    padding: 0;
    margin: 0 0 20px 0;
    text-align: left;
    width: auto;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .footer .skew-btn span {
    transform: skewX(0);
    -webkit-transform: skewX(0);
    -moz-transform: skewX(0);
    -ms-transform: skewX(0);
  }
  .footer-link {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 40px 0 0;
  }
  .footer-link-box li {
    margin: 5px 8px 5px 0;
    width: auto;
    min-width: 100px;
  }
  .footer-link-box li:nth-child(even) {
    margin-right: 10px;
  }
  .footer {
    margin: 0;
    padding: 35px 0 0;
  }
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-bottom {
    margin: 45px 0 0;
  }
  .md_visible {
    display: block !important;
  }
  .md_hidden {
    display: none !important;
  }
  .feature-thumb-big,
  .feature-thumb-small {
    height: 110px;
  }
  .banner img {
    min-height: 450px;
    object-fit: cover;
    opacity: 0.8;
  }
  .banner-caption {
    bottom: 60px;
  }
  .btn.blue-btn {
    width: 200px;
    height: 80px;
    font-size: 15px;
    line-height: 60px;
    padding: 10px 20px;
    background-size: 200px;
  }
  .banner-caption h1 {
    font-size: 24px;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.8);
  }
  .swiper-slider1 {
    height: 400px;
  }
  .owl-carousel .owl-nav .owl-next {
    background-position: 0 0;
    width: 45px;
    height: 50px;
    right: -55px;
    background-size: 88px auto;
  }
  .owl-carousel .owl-nav .owl-prev {
    background-position: -44px 0;
    width: 45px;
    height: 50px;
    left: -55px;
    background-size: 88px auto;
  }
  .owl-carousel .owl-nav .owl-prev:hover {
    background-position: 1px 0;
    background-size: 88px auto;
  }
  .owl-carousel .owl-nav .owl-next:hover {
    background-position: -44px 0;
    background-size: 88px auto;
  }
  .swiper-button-next {
    background-position: -44px 0;
    width: 45px;
    height: 50px;
    right: 68px;
    background-size: 88px auto;
  }
  .swiper-button-prev {
    background-position: -44px 0;
    width: 45px;
    height: 50px;
    left: 68px;
    background-size: 88px auto;
  }
  .top-trending {
    padding: 60px 0;
  }
  .smoke-bg-left,
  .smoke-bg-right {
    width: 150px;
  }
  .owl-carousel-video .owl-item.center .item-video {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    margin: 27px 0 0;
  }
  .owl-carousel-video .owl-item {
    padding: 30px 0;
  }
  .main-menu {
    padding: 0 0 0 34px;
  }
  .detail-leftbar .card.card-darktheme {
    margin: 40px 0 0;
  }
  .about-game {
    margin: 30px 0;
  }
  .more-game-section {
    margin: 15px 0 80px;
  }
  .btn-group1 .btn.custom-btn {
    width: 160px;
    height: 60px;
    font-size: 15px;
    line-height: 39px;
    padding: 10px 15px;
    letter-spacing: 1px;
    background-size: 160px;
  }
  .featuredbox-left {
    height: auto;
    margin: 0 0 15px;
  }
  .about-section {
    padding: 0 0 30px;
  }
  .map iframe {
    height: 300px;
  }
  .dont-acc,
  .forgot-password {
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
  .contact-left {
    margin: 0 0 40px;
  }
  .about-right {
    margin: 0 0 40px;
  }
  .about-right svg {
    height: 480px;
  }
  .page-header {
    height: auto;
    line-height: 1.54;
    min-height: 0;
    padding: 15px;
  }
  .dashboard-rightbar h2 {
    font-size: 20px;
  }
  .table td,
  .table th {
    padding: 20px 7px;
    font-size: 15px;
  }
  .table td:first-child,
  .table th:first-child {
    padding-left: 10px;
  }
  .table thead th {
    font-size: 15px;
    padding: 10px 7px 10px;
  }
  .page-header {
    margin: 0 0 15px;
  }
  .card-child {
    margin-bottom: 15px;
  }
  .dashboard-form label,
  .dashboard-form input {
    font-size: 17px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .select-plan-section .col-md-3.col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .pricingTable .price-value {
    margin: 0 -10px 15px;
  }
  .pricingTable .pricing-content {
    padding-bottom: 15px;
  }
  .pricingTable .pricingTable-signup {
    width: 60%;
  }
  .btn {
    height: 40px;
    line-height: 17px;
    padding: 10px 25px;
    font-size: 16px;
  }
  .btn.btn-small {
    min-width: 120px;
    height: 40px;
    line-height: 17px;
    font-size: 16px;
  }
  .btn.btn-link {
    font-size: 16px;
    line-height: 17px;
    height: 40px;
  }
  .header .btn {
    min-width: 120px !important;
  }
  .btn.btn-large {
    font-size: 16px;
    height: 40px;
    line-height: 17px;
  }
  .login-btn-group {
    max-width: 460px;
  }
  .page-header__img2 {
    width: 160px;
    top: -15px;
    right: 0;
  }
  .appoinment-content .page-header h3 {
    font-size: 20px;
  }
  .appoinment-content .page-header {
    padding: 15px;
  }
  .time-slot-box .radio-buttons {
    margin: 0 15px 15px 0;
  }
  .time-slot {
    padding: 12px 20px;
  }
  .page-header__img3 {
    width: 170px;
    top: -20px;
    right: 0;
  }
  .view-appoinment {
    padding: 0;
  }
  .modal-dialog {
    width: auto;
    margin: 0.5rem;
  }
  .appoinment-booked .modal-body,
  .appoinment-booked-failed .modal-body {
    padding: 20px;
  }
  .booked-appoinment {
    padding: 30px;
  }
  .booked-appoinment p {
    padding: 0;
  }
  .booked-appoinment-img {
    width: 300px;
  }
  .page-header__img4 {
    width: 200px;
  }
  .reward-content .page-header {
    padding: 80px 10px 30px;
  }
  .page-header__img4 {
    width: 170px;
    top: -50px;
    right: 0;
  }
  .catalog-content .page-header {
    padding: 15px;
  }
  .page-header__img5 {
    width: 170px;
    top: -50px;
    right: 0;
  }
  .member-wallet-content .page-header,
  .member-voucher-content .page-header,
  .catalog-content .page-header,
  .package-detail-content .page-header,
  .reward-content .page-header,
  .member-service-content .page-header {
    padding-top: 80px;
    padding-bottom: 30px;
    margin: 50px 0 15px;
  }
  .page-header__img7 {
    width: 170px;
    top: -75px;
    right: 0;
  }
  .page-header__img8,
  .page-header__img6 {
    width: 170px;
    top: -50px;
    right: 0;
  }
  .home-content .page-header {
    padding-top: 50px;
    padding-bottom: 30px;
    margin: 50px 0 15px;
  }
  .page-header__img {
    width: 140px;
    position: absolute;
    right: 0;
    bottom: auto;
    top: -70px;
  }
  .header-right.head-r1
    .lang-sel
    select.form-control:not([size]):not([multiple]) {
    height: 40px;
  }
  .header-right.head-r1 .lang-sel {
    margin-left: 20px;
  }

  /* new css start */
  .login-right .form-container {
    padding: 20px;
  }
  .form-footer {
    margin-top: 15px;
  }
  .login-tagline {
    padding: 0 20px;
  }

  .banner-img .big-logo {
    display: block;
    margin: 0 auto 20px;
  }
  .banner-img {
    text-align: center;
    margin-bottom: 120px;
  }
  .filter-section-inner {
    margin-bottom: 100px;
  }
  .header .navbar {
    padding: 25px 0;
  }
  .accordion-body-sec .accordion {
    margin: 90px 0 160px;
  }
  ul.desktop-view-menu {
    display: none;
  }
  .vendor-desc {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.333333px;
    font-weight: 300;
    max-width: 610px;
    width: 100%;
    margin: 0 auto 20px;
  }
  .vendor-event-carousel .item img {
    height: 280px;
    object-fit: cover;
  }
  .vendor-event-carousel .owl-carousel .owl-nav button {
    width: 45px;
    height: 45px;
  }
  .vendor-event-carousel .owl-carousel .owl-nav button span {
    line-height: 45px;
  }
  .vendor-event-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: 45px;
  }
  .vendor-event-carousel .owl-nav button.owl-next {
    position: absolute;
    right: 45px;
  }
  .vender-data p {
    font-size: 20px;
    line-height: 24px;
  }
  .pro-vender-section {
    height: 100%;
  }
  /* new css End */
  .joshn-weds-inner h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .videography-block {
    width: 48%;
  }
  .joshn-weds-inner .event-date-sec {
    width: 48%;
    text-align: center;
  }
  .event-budget-block li {
    margin-right: 2%;
  }
  .event-brief-block .event-brief {
    width: 100%;
  }
  .event-document {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
  .documents-downloads {
    min-height: 190px;
  }
  .joshn-wedding-popup .event-document {
    width: 100%;
    margin: 25px 0px 0px 0px;
  }
  .common-description h4 {
    margin: 0px 0px 12px 40px;
  }
  .filter-section-inner {
    margin-bottom: 50px;
  }
  .event-budget-block ul {
    padding: 0px 2px 10px 2px;
    text-align: center;
  }
  .filter-section-row.tablet-view .search-section {
    /* margin-bottom: 10px; */
  }
  .joshn-wedding-popup .event-budget-block p {
    margin: 0px 0px 0px 8px;
  }
  .joshn-wedding-popup .event-budget-block li {
    margin-right: 1%;
  }
  .joshn-wedding-popup {
    padding: 54px 30px 60px 30px;
  }
  .joshn-wedding-popup .documents-downloads {
    height: auto;
  }
  .common-description h4 {
    text-align: center;
  }
  .left-sidebar.sidebar-toggle {
    top: 163px;
  }
  .zindex_set {
    z-index: 0 !important;
  }
  .event-listing-details-wrapper .event-listing-date-inner {
    margin-left: 0;
    margin: 5px;
    margin-left: 0;
  }
  .event-listing-date p:last-child {
    margin-left: 10px;
    margin-top: 0px;
  }
  .event-listing-consumer-block .event-listing-consumer-inner {
    margin-bottom: 10px;
  }

  .user-chat-bottom {
    padding: 10px;
  }
  .user-chat-bottom .reviewer-review .reviewer-review-top,
  .user-chat-bottom .reviewer-review .reviewer-review-top p {
    /* color: #ffffffbd; */
  }
  .recent-review-content-block .user-review-left {
    width: 100%;
  }
  .recent-review-content-block .user-review-left {
    height: auto;
  }
  .recent-review-content-block .user-review-right {
    width: 100%;
    margin-left: 0;
  }
  .vendor-popup-slider-inner .owl-nav {

      top: auto;
      transform: unset;
      bottom: -50px;
  }
  .page-heading {
    flex-direction: column;
  }
  .chats-textarea-form {
  width: 90%;
}
.chats-textarea button.chats-submit-btn {
width: 50px;
}
.star_review .review-date, .star_review .review-star {
margin-right: 10px;
}
svg{
display: block;
}
.star-ratings {
position: relative;
top: 0px;
}

.reviewer-review .reply-btn {
display: flex;
justify-content: flex-end;
}
.chats-textarea-form{
width: 100%;
}
.Subscription_details .right-content-block .setting-cards  .setting-inner-card.premium-card {
margin-left: 11px !important;
}

}
/* 991 ends */
@media only screen and (max-width: 767px) {
  /* .container { max-width: 640px; } */
  .heading {
    margin: 0 0 20px;
  }
  .heading-2 h3 {
    font-size: 16px;
    line-height: 1.5;
    padding: 0;
    margin: 0 0 20px;
  }
  .mb-100 {
    margin-bottom: 40px;
  }
  h1,
  .h1 {
    font-size: 18px;
  }
  h2,
  .h2 {
    font-size: 18px;
    line-height: 1.3;
  }
  .card-header h2,
  .detail-leftbar h2,
  .heading-big h2 {
    font-size: 16px;
  }
  .footer-logo {
    left: -10px;
  }
  .swiper-slider1 {
    height: 300px;
  }
  .owl-carousel .owl-nav .owl-prev {
    left: 0;
  }
  .owl-carousel .owl-nav .owl-next {
    right: 0;
  }
  .swiper-slider-outer {
    padding: 0;
    margin: 0;
  }
  .swiper-button-prev {
    left: 10px;
  }
  .swiper-button-next {
    right: 10px;
  }
  .top-trending {
    padding: 40px 0;
  }
  .games-promotion {
    margin: 15px 0 25px;
  }
  .menu-toggle-outer {
    width: 100px;
    top: 15px;
  }
  .banner img {
    opacity: 0.5;
  }
  .btn.blue-btn:hover::before {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
  }
  .heading h2 {
    font-size: 20px;
    letter-spacing: 1.5px;
    margin: 0 0 25px;
  }
  .btn.blue-btn {
    margin: 10px 0 0;
  }
  .inner-header .menutoggle.menu-close {
    margin: 20px 0 0;
    padding: 0;
    float: right;
    width: 40px;
  }
  .main-menu li {
    margin: 10px 0;
  }
  .main-menu li a {
    font-size: 17px;
  }
  .banner-caption h1 {
    font-size: 20px;
  }
  .info-short-box h4 {
    font-size: 18px;
    margin: 0 0 15px;
  }
  .owl-carousel-video .owl-item.center .item-video {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    margin: 0;
  }
  .owl-carousel-video .owl-item {
    padding: 0;
  }
  .feature-section {
    margin: 0 0 25px;
  }
  body {
    font-size: 15px;
  }
  #thumbs .item {
    height: 80px;
  }
  .card-body-border {
    padding: 15px 20px;
  }
  .detail-leftbar .card-darktheme .card-header {
    padding: 18px 20px;
  }
  .header-margin {
    height: 120px;
  }
  #thumbs {
    margin: 0;
  }
  .breadcrumb {
    font-size: 14px;
    padding: 15px 0;
  }
  #thumbs .current .item {
    border: 3px solid #31485a;
  }
  .re-unit {
    width: 130px;
  }
  .re-detail {
    padding: 0 0 0 140px;
  }
  p {
    margin: 0 0 20px;
  }
  .card-header h4 {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  .map iframe {
    height: 260px;
  }
  .forgot-password {
    max-width: 100%;
  }
  .signup-page {
    padding: 20px 0 50px;
  }
  .dashboard-content {
    padding: 32px 15px 32px;
  }
  .desh-headerleft {
    padding: 10px 15px;
  }
  .page-header h1,
  .page-header h2 {
    font-size: 20px;
  }
  #sidebar-wrapper {
    width: 250px;
    left: -250px;
  }
  #dash_wrapper.toggled-wrapper {
    margin-left: 250px;
  }
  .sidebar-nav li a {
    padding: 10px 10px 10px 40px;
    font-size: 16px;
  }
  .sidebar-nav li {
    line-height: 36px;
  }
  .sidebar-icon {
    left: 10px;
    font-size: 18px;
    margin-top: -2px;
    width: 20px;
  }
  /* .dashboard-header .navbar-brand { width: 80px; } */
  .logged-img {
    width: 46px;
    height: 46px;
  }
  .dashboard-form label,
  .dashboard-form input {
    font-size: 16px;
  }
  .dashboard-form {
    padding: 15px 15px;
  }
  .dashboard-rightbar h2 {
    font-size: 18px;
  }
  .membershipForm {
    padding: 104px 60px;
  }
  .custom-form.register-form-1 {
    padding: 80px 90px 70px;
    margin-top: -40px;
  }
  div#formpopup {
    padding: 0 !important;
  }
  .formContentPop .modal-dialog {
    width: 100%;
    max-width: 90%;
  }
  .custom-form.welcome-screen {
    padding: 40px;
  }
  .custom-form p {
    font-size: 16px;
    line-height: 1.34;
  }
  .custom-form h1,
  .main-heading-small h1 {
    font-size: 22px;
    margin: 0 0 15px;
    letter-spacing: 0;
  }
  .header-right {
    margin: 0 0 15px;
  }
  .btn {
    padding: 10px 20px;
  }
  .header .btn {
    min-width: 90px !important;
  }
  .login_screen .checkboxbtn {
    margin-right: 0;
    font-size: 15px;
    white-space: pre-wrap;
  }
  .forgot-password {
    font-size: 12px;
  }
  /* .login-bg.welcome-bg { background-image: url(../img/welcome-bg.jpg); background-position: center 104%; background-size: 500px auto; } */
  .backtologin {
    right: 15px;
    bottom: 15px;
    font-size: 16px;
  }
  .logged-in {
    padding: 0 0 0 15px;
    min-width: 0;
  }
  .logged-img {
    width: 40px;
    height: 40px;
    margin-left: 0;
    margin-right: 0;
  }
  .headR1-icon + .headR1-icon {
    margin-left: 10px;
  }
  .form-group.has-search {
    width: 240px;
  }
  .page-header {
    margin: 50px 0 15px;
    padding-top: 70px;
    padding-bottom: 30px;
  }
  .page-header h3 {
    font-size: 20px;
  }
  .page-header p {
    font-size: 16px;
  }
  .main-content-head h3 {
    font-size: 20px;
  }
  .main-content {
    padding: 20px;
  }
  .appoinment-card {
    margin-bottom: 20px;
  }
  .view-appoinment .modal-content {
    padding: 30px;
  }
  .view-appoinment .modal-dialog {
    max-width: 96%;
    margin: 0 auto;
  }
  .view-appoinment .bub-1 {
    top: 35px;
    left: 10px;
  }
  .view-appoinment .bub-2 {
    bottom: 40px;
    right: 10px;
  }
  .bub-3 {
    bottom: 10px;
    left: 10px;
  }
  .booking-detail {
    width: 100px;
  }
  .modal-content-wrap .modal-header h5 {
    font-size: 20px;
  }
  .appoinment-content .page-header,
  .catalog-content .page-header {
    padding-top: 80px;
    padding-bottom: 30px;
  }
  .profile-detail {
    padding: 20px;
  }
  .profile-content {
    flex-direction: column;
  }
  .profile-image {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #dcdcdc;
  }
  .profile-detail {
    width: 100%;
  }
  .profile-uploadphoto {
    margin: 40px auto 0;
  }
  .booked-appoinment h2 {
    font-size: 25px;
  }
  .booked-appoinment p {
    font-size: 16px;
  }
  .content-head-lt {
    margin-bottom: 15px;
  }
  .page-header__img4 {
    top: -50px;
  }
  .claim-content-wrap h4 {
    font-size: 20px;
  }
  .reward-card-inner h4 {
    font-size: 18px;
  }
  .reward-count {
    font-size: 22px;
    top: 0px;
  }
  .claim-content-wrap h4 {
    font-size: 18px;
  }
  .claim-content-wrap p {
    font-size: 16px;
  }
  .table-head-wrap {
    padding: 15px;
  }
  .dropdown.filter .btn {
    padding: 5px;
    font-size: 16px;
    height: 30px;
    width: 86px;
  }
  .page-header__img3,
  .page-header__img5 {
    top: -50px;
  }
  .bef-aft-box {
    width: 140px;
  }
  .service-modal .modal-dialog {
    max-width: 96%;
    margin: 0 auto;
  }
  .service-modal .modal-content,
  .voucher-modal .modal-content {
    padding: 30px;
  }
  .catalog-product {
    padding: 0 15px 30px;
  }
  .profile-det,
  .feedback-det {
    width: 100%;
    padding: 28px 30px;
  }
  .profile-det .form-group.w-9 {
    width: 100%;
    position: relative;
  }
  .card--inner {
    padding: 15px 15px 20px;
    min-height: 0;
    display: flex;
  }
  .dashboard-content .card-content {
    margin: 0 0 0 15px;
  }
  .dashboard-page .card h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .dashboard-page .card-icon {
    width: 48px;
    height: 48px;
    line-height: 48px;
    padding: 0 13px;
  }
  .vendor-video-carousel .owl-nav button.owl-next {
    right: 0;
  }
  .vendor-video-carousel .owl-nav button.owl-prev {
    left: 0;
  }

  /* new css start */

  .cust-remove-padding {
    width: 100%;
  }
  .login-left-sec {
    display: none;
  }
  .login-right h1 {
    font-size: 20px;
    margin: 0 0 5px;
  }
  .other-account {
    margin: 10px 0 20px;
  }
  .login-right .footerlink {
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 3px;
  }
  .header .navbar {
    padding: 20px 0;
  }
  .footer-list-sec ul {
    margin: 0;
  }
  .footer-list-sec {
    margin-bottom: 25px;
  }
  .footer-data {
    padding: 25px 0 5px;
  }
  .footer-brand-sec {
    margin-bottom: 25px;
  }
  .footer-accordion .content {
    height: 0;
  }
  .footer-accordion h5::before {
    display: unset;
  }
  .footer-row {
    margin-bottom: 50px;
  }

  .banner-img .logo-name {
    max-width: 300px;
    width: 100%;
  }
  .scroll-section {
    padding: 50px 0 50px;
    display: none;
  }
  .scroll-section h2 {
    font-size: 20px;
    line-height: 40px;
  }
  .vender-data p {
    font-size: 20px;
    line-height: 28px;
  }
  .vendor-pro {
    display: block;
  }
  .vendor-pro .rating {
    margin-left: 16px;
  }

  .vendor-bg-sec {
    width: 100%;
    position: relative;
    height: 650px;
  }
  .vendor-section-inner .vendor-name {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 13.1667px;
  }
  .vendor-event-carousel .owl-carousel .owl-nav button {
    width: 40px;
    height: 40px;
  }
  .vendor-event-carousel .owl-carousel .owl-nav button span {
    font-size: 45px;
    line-height: 40px;
    position: relative;
    top: -5px;
  }
  .vendor-event-carousel .owl-nav {
    top: 45%;
    margin: -50px auto 0;
  }
  .vendor-desc {
    font-size: 18px;
    line-height: 22px;
  }
  .avatar-upload-sec.profile-avtar-sec {
    width: 100%;
    height: 300px;
    border-radius: 15px;
  }
  .desc-sec textarea.form-control {
    height: 200px;
    resize: none;
  }
  /* .vendor-event-carousel .item img{
    height: 200px;
    object-fit: cover;
} */
  .vendor-video-inner .item {
    padding: 0 85px;
  }
  .avatar-section {
    margin-bottom: 20px;
    max-width: 400px;
  }
  .avatar-section .avatar-upload-sec {
    margin: 20px auto 10px;
  }
  .avatar-section p {
    /* display: none; */
  }
  .banner-event-list .event-service li {
    margin-left: 0;
    text-align: center;
    width: 100%;
  }
  .event-budget-block li {
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }
  .event-budget-block ul {
    padding: 0px 0px 10px 0px;
    text-align: center;
  }
  .joshn-wedding-popup .event-budget-block li {
    margin-right: 0px;
  }
  .joshn-wedding-popup h4 {
    text-align: center;
  }
  .joshn-wedding-popup .event-budget-block {
    margin-top: 20px;
  }
  .joshn-wedding-popup .event-service li {
    margin-left: 20px;
  }
  .joshn-wedding-popup .event-service {
    margin-top: 20px;
    text-align: center;
  }
  .videography-block,
  .joshn-weds-inner .event-date-sec {
    width: 100%;
    text-align: center;
  }
  .joshn-weds-inner .event-date-sec {
    margin-top: 10px;
  }
  .joshn-wedding ul {
    margin-top: 20px;
  }
  .banner-inner-block,
  .steve-birthday,
  .nancy-pool {
    margin: 0px auto 40px;
  }
  /* new css End */
  .event-budget-block li {
    margin-top: 10px;
  }
  .event-budget-block li:first-child {
    margin-top: 0px;
  }


  .user-chat-top-box {
    padding: 15px 10px;
  }
  /* .user-chat-box-left {
      width: 32%;
  } */
  /* .user-chat-box-left {
    width: 55%;
  } */
  .user-chat-box-left h4 {
    font-size: 12px;
  }
  .user-chat-top-box.d-flex .btn.btn_normal {
    font-size: 12px !important;
  }
  .user-chat-top-box {
    background: #1fbcff;
    /* justify-content: space-between; */
    width: 100%;
  }
  /* .user-review-block-inner {
    min-height: 100px;
    height: auto !important;
    max-height: 530px !important;
  } */
  .recent-review-content-block .user-review-left {
    height: auto;
    min-height: 200px;
    max-height: 700px;
  }
  .event-listing-type ul li {
    padding-left: 0;
    margin-left: 0;
    padding-top: 10px;
  }
  .event-listing-type-date {
    display: flex;
    flex-direction: column;
  }
  .event-listing-budget-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .event-listing-type ul {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .event-listing-service-list ul li {
    margin-left: 0;
  }
  .event-listing-budget-inner {
    flex: 100%;
  }
  .event-listing-service-head {
    width: 100%;
    margin-bottom: 10px;
  }
  .event-listing-service-block {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .event-listing-service-list ul {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column;
  }
  .sidenav .nav-link img {
    max-width: 25px;
  }
  .header .btn {
    min-width: 63px !important;
    align-self: center;
  }
  .filter-section-row {
    display: block !important;
    display: flex !important;
    flex-direction: column;
  }
  .common-select-sec {
    min-width: auto;
    width: 100%;
  }
  .search-section {
    min-width: 100%;
  }
  .event-listing-budget-inner,
  .event-listing-consumer-inner {
    margin-left: 0;
  }
  .event-listing-budget-inner {
    margin-bottom: 10px;
  }
  .event-listing-service-head {
    margin-bottom: 20px;
  }
  .event-listing-service-list ul li {
    margin-bottom: 10px;
  }
  .event-listing-date {
    width: 70%;
    text-align: start;
    margin-top: 20px;
  }
  .event-listing-type ul li:first-child {
    padding-left: 0;
  }
  .event-listing-type ul li {
    padding: 10px 10px 10px 10px;
  }
  .event-listing-type-date {
    padding: 29px 15px;
  }
  .sidebar-mob-menu {
    top: 110px;
  }
  .filter-section-row.tablet-view {
    display: none !important;
  }
  .form-group.common-select-sec.category-sec {
    margin-bottom: 0;
  }
  .report-popup-wr {
    right: 0;
  }
  .recent-review-content-block .right-box {
    flex-direction: column;
    gap: 0;
  }
  .sidebar-content-wr {
    padding-bottom: 100px;
  }
  .chat-note-text {
    bottom: -78px;
  }
  .common-select-sec{
    max-width: 100%;
  }

  .reply-button {
    flex-direction: column;
  }
  .star_review {
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
  }

  .reviewer-review .reply-btn {
    display: unset !important;
  }
  .vendor-video{
    width: 100% !important;
    height: unset !important;
    margin-bottom: 20px;
  }
  .video_input{
    height: 50px !important;
    margin: 10px 0 0;
  }
  .event-listing-type-date{
    align-items: flex-start;
  }
  .event-listing-service-list ul li {
    margin-left: 6px;
    padding-left: 0;
    margin-left: 0;    }

    .joshn-wedding .event-listing-consumer-block {
      flex-direction: column;
      gap: 0;
  }

  .joshn-wedding .event-listing-date{
    flex-direction: column;
  }
  .Subscription_details .right-content-block .setting-cards  .setting-inner-card.premium-card {
    min-height: 380px;
    width: 45%;
    max-width: 100%;
    margin-left: 25px !important;
    /* margin-left: 3% !important; */
}

  }
/* 767 ends */
@media only screen and (max-width: 576px) {
  .video-sec video {
    border-radius: 0px !important;
  }
  .top-btn{
    text-align: center;
  }
  .top-btn .btn{
    margin-top: 10px;
  }
  .promo-video-sec.common-label-right span.small{
    font-size: 12px;
  }
  .vender-data .content-css {
    display: none;
  }
  .owl-carousel .owl-item .vendor-photo-carousel {
    display: block !important;
    width: 100% !important;
    height: 240px !important;
  }
  .custom-tab .nav-tabs li a {
    min-width: 0;
    line-height: 30px;
    padding: 4px;
    height: 44px;
    font-size: 14px;
  }
  .custom-tab .nav-tabs li {
    width: 33.33%;
    padding: 10px 10px;
  }
  .feature-tab {
    margin: 0 -3px;
  }
  .feature-tab li {
    margin: 10px 0;
    padding: 0 3px;
    width: 25%;
  }
  .swiper-slider1 {
    height: 240px;
  }
  #thumbs .item {
    height: 60px;
  }
  .card-darktheme .card-body {
    padding: 15px 15px;
  }
  .card-darktheme .card-header {
    padding: 15px 15px;
  }
  .btn-group1 .btn.custom-btn {
    width: 120px;
    height: 44px;
    font-size: 12px;
    line-height: 27px;
    padding: 10px 10px;
    background-size: 126px;
    min-width: 0;
  }
  .feature-thumb-big,
  .feature-thumb-small {
    height: 80px;
  }
  /* .custom-form { padding: 30px 25px 30px; } */
  .about-right svg {
    height: 300px;
  }
  .radio-inline .radio-btn {
    min-width: 0;
  }
  .membershipForm {
    padding: 75px 30px;
  }
  .select-plan-section .col-md-3.col-sm-6 {
    max-width: 100%;
    flex: 0 0 1000%;
  }
  .membershipForm {
    padding: 45px 60px 210px;
    background-position: 0 -94px;
  }
  .custom-form.register-form-1 {
    padding: 70px 50px 50px;
  }
  .header-margin {
    height: 100px;
  }
  .header {
    padding: 15px 0;
  }
  .login-bg.welcome-bg {
    background-size: 360px auto;
  }
  .custom-form.signup_screen {
    padding: 30px 25px 30px;
  }
  .profile-name {
    display: none;
  }
  .main-content-head {
    margin-bottom: 30px;
  }
  .main-content-head h3 {
    margin-bottom: 15px;
  }
  .booking-icons {
    margin: 15px 5px 0 0;
  }
  .booking-detail {
    font-size: 16px;
  }
  .view-appoinment .modal-content {
    padding: 15px;
  }
  .booking-info {
    font-size: 13px;
  }
  .view-appoinment button.close {
    top: 0px;
    right: 10px;
  }
  .view-appoinment .bub-1 {
    left: 4px;
  }
  .view-appoinment .bub-2 {
    right: 4px;
  }
  .dashboard-rightbar h2 {
    margin-bottom: 0;
  }
  .time-slot {
    padding: 12px 15px;
  }
  .booked-appoinment-img {
    width: 200px;
  }
  .booked-appoinment,
  .appoinment-booked .modal-body,
  .appoinment-booked-failed .modal-body {
    padding: 15px;
  }
  .booked-btn-group .btn {
    height: 40px;
    min-width: 100px;
    line-height: 1;
  }
  .booked-btn-group .btn.btn-trans {
    margin-left: 15px;
  }
  .claim-content {
    padding: 15px;
  }
  .last-days {
    margin-right: 35px;
  }
  .appoinment-booked-failed .booked-appoinment {
    padding: 50px 20px;
  }
  .appoinment-booked-failed .close {
    top: 25px;
    right: 25px;
  }
  .service-modal .modal-body {
    padding: 0 15px;
  }
  .bef-aft-box:first-child {
    margin-right: 10px;
  }
  .service-modal .modal-content {
    padding: 15px;
  }
  .service-modal .modal-content-wrap .modal-header {
    padding: 15px 0 5px 25px;
  }
  .bef-aft {
    margin: 20px 0 30px;
  }
  .service-modal .modal-content,
  .voucher-modal .modal-content {
    padding: 15px;
  }
  .table thead th {
    vertical-align: top;
  }
  .profile-detail {
    padding: 10px;
  }
  .profile-det,
  .feedback-det {
    width: 100%;
    padding: 15px;
  }
  /* new css */
  .data-pagination a {
    width: 30px;
    height: 30px;
  }
  .accordion {
    margin: 20px 0;
  }
  .navbar-brand.desktop-brand {
    display: none;
  }
  .menu-btn {
    display: block;
  }
  .navbar-brand {
    width: 100%;
    text-align: center;
    display: block;
  }
  .vendor-events {
    display: block;
  }
  .vendor-events li {
    margin-bottom: 10px;
  }
  .vendor-emenities {
    display: block;
    margin: 30px 0;
  }
  .vendor-emenities li {
    margin-bottom: 10px;
  }
  .vendor-profile-rating {
    max-width: 270px;
    margin: 33px auto 0;
    justify-content: center;
    display: flex;
    gap: 8px;
  }
  .vendor-profile-rating a span {
    display: none;
  }
  .vendor-section-inner .vendor-name {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 5.66667px;
  }

  .vendor-section .vender-data {
    display: none;
  }
  .vendor-video-carousel .container {
    padding: 0;
  }
  .video-sec {
    height: 100vh;
  }
  .vender-video {
    margin: 0;
    border-radius: 0;
  }
  span.blur-bg {
    background: transparent;
    backdrop-filter: unset;
    border: 1px solid;
  }
  .links-section {
    margin-bottom: 100px;
  }

  .header .navbar a.nav-link span {
    width: 20px;
    height: 20px;
  }
  .header .navbar a.nav-link img {
    margin-right: 10px;
  }
  .vender-data .mobile-view-user-profile {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: absolute;
    bottom: 20px;
    justify-content: center;
    align-items: center;
  }
  .pro-vendor-video-sec {
    position: relative;
  }
  .desktop-view-user-profile {
    display: none;
  }
  .pro-vender-section .container {
    padding: 0;
  }
  .pro-vender-profile {
    position: absolute;
    z-index: 5;
    padding: 15px;
  }
  .vender-data {
    position: absolute;
    bottom: 0;
    padding: 15px;
  }
  .video-sec {
    height: 100vh;
    margin-bottom: 60px;
  }
  .vender-video {
    border-radius: 0;
    overflow: unset;
  }
  .vendor-event-carousel {
    margin: 40px 0;
    position: relative;
  }
  .vendor-event-carousel .owl-dots {
    margin-top: 20px;
  }
  .review-inner.common-inner-container .owl-carousel {
    padding: 0 50px;
  }

  .video-sec::after {
    content: "";
    position: absolute;
    background: linear-gradient(0deg, #000000 -10.67%, rgba(0, 0, 0, 0) 88.72%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  span.blur-bg {
    background: transparent;
    border: 1px solid;
    backdrop-filter: unset;
  }
  .pro-vender-section {
    padding: 0;
  }
  .paginatin-sec {
    margin: 50px 0 50px;
  }
  .accordion h1 {
    margin: 150px 0 35px 0;
  }
  .data-pagination a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 7.5px;
  }
  .mobile-search-gradient {
    display: flex;
  }
  .filter-section-row {
    display: none;
  }
  .vendor-video-inner .item {
    padding: 0;
  }
  .vendor-video-carousel .vender-data {
    display: none;
  }
  .vender-img {
    width: 132px;
    height: 132px;
  }
  .vendor-section {
    margin-top: -200px;
  }
  .verifi-sec {
    width: 25px;
    height: 25px;
    top: -8px;
    right: -8px;
  }
  .review-sec {
    margin: 70px 0 60px;
  }
  .pro-vender-section {
    /* height: 100vh; */
  }
  .banner-img {
    text-align: center;
    margin-bottom: 50px;
  }
  .scroll-section {
    padding: 0px 0 0px;
    display: block;
  }
  span.scroll-btn {
    display: block;
    margin: 30px 0 10px;
  }
  .mobile-menu-inside {
    margin: 15px 0 0;
    display: block;
  }
  .mobile-menu-inside .vender-img {
    width: 86px;
    height: 86px;
  }
  .mobile-menu-inside .vendor-name {
    text-align: center;
    margin: 10px 0 5px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 3.16667px;
    text-transform: uppercase;
  }
  .mobile-menu-inside .vendor-profile-designation {
    text-align: center;
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 141.3%;
    letter-spacing: 1.16667px;
    text-transform: uppercase;
  }
  .next-btn-sec a.cancle-btn {
    display: block;
    text-align: center;
    padding: 6px 13px;
  }
  .next-btn-sec .btn {
    /* display: block;
      min-width: unset;
      max-width: 100%;
      margin: 0 0 15px 0; */

    display: block;
    /* min-width: unset; */
    max-width: 100%;
    margin: 0 0 15px 15px;
  }
  .next-btn-sec a.cancle-btn:hover {
    padding: 6px 13px;
  }
  .videography-block,
  .joshn-weds-inner .event-date-sec {
    width: 100%;
  }
  .videography-block {
    margin-bottom: 10px;
  }
  .banner-inner-block,
  .steve-birthday,
  .nancy-pool {
    margin: 0px auto 50px;
  }
  .joshn-wedding-popup {
    padding: 40px 20px 40px 20px;
  }
  .popup-map {
    margin-top: 20px;
  }
  .common-description h4 {
    margin: 0px 0px 12px 0px;
  }
  .event-document,
  .event-brief-block .event-brief {
    text-align: center;
  }
  .banner-event-list {
    padding-top: 20px;
  }
  .joshn-wedding-popup .closebtn {
    right: 20px;
    top: 10px;
  }
  .joshn-weds-inner h4 {
    margin-bottom: 20px;
  }
  .joshn-wedding .load-more {
    margin-top: 35px;
  }
  .middle-section .event-sec ul {
    gap: 20px;
    flex-wrap: wrap;
  }
  .event-listing-type ul {
    gap: 20px;
}

.Subscription_details .right-content-block .setting-cards  .setting-inner-card.premium-card {

  width: 100%;
  max-width: 100%;
  margin-left: 0 !important;
  margin-bottom: 45px;
}
.web-alignment {
  display: none !important;
}
.mobile-alignment {
  display: block !important;
} 
}
@media only screen and (max-width: 420px) {
  .vendor-review-send-btn button {
    margin-left: 10px;
    margin-top: 10px;
  }
  .about-right svg {
    height: 270px;
  }
  .custom-form.welcome-screen {
    padding: 30px 20px;
  }
  .booking-detail {
    font-size: 14px;
  }
  .last-days {
    font-size: 12px;
  }
  .table-head-wrap {
    padding: 15px 15px 0;
  }
  .table-heading,
  .table-head-wrap .dropdown.filter {
    margin-bottom: 15px;
  }
  .headR1-icon {
    width: 35px;
    height: 35px;
  }
  .headR1-icon img {
    width: 12px;
  }
  .logged-in {
    padding: 0 0 0 5px;
  }
  .lang-sel select.form-control:not([size]):not([multiple]) {
    height: 30px;
    padding: 5px;
  }
  .lang-sel select.form-control:not([size]):not([multiple]) {
    font-size: 14px;
  }
  .lang-sel::after {
    top: 49%;
  }
  .header-right.head-r1 .lang-sel {
    margin-left: 15px;
  }

  /* new css */
  .scroll-section h2 {
    font-size: 12px;
    line-height: 25px;
  }
  .vender-data p {
    font-size: 16px;
    line-height: 26px;
  }
  .banner-img .logo-name {
    max-width: 230px;
    width: 100%;
  }
  .review-inner.common-inner-container .owl-carousel {
    padding: 0 20px;
  }
  .filter-section-row.tablet-view {
    display: none;
  }
  ul.event-service {
    display: none;
  }
  .banner-event-list {
    padding-top: 0px;
  }
  .joshn-weds-inner {
    padding: 25px 10px 25px 10px;
    text-align: center;
  }
  .joshn-wedding {
    margin-bottom: 50px;
  }
  .banner-inner-block.nancy-pool {
    margin: 0 auto 20px;
  }
  .documents-downloads {
    min-height: 135px;
  }
  .header .btn {
    align-self: center;
    min-width: 34px !important;
    padding: 11px 13px;
  }
  .pro-vender-section  .vender-data {
    bottom: 65px !important;
    padding: 12px !important;
}
.upload-video-sec {
  height: 255px;
}
.vendor-video video{
/* object-fit: cover; */
}
.event-listing-date p:last-child {
margin-left: 0;
margin-top: 5px;
}
}
@media only screen and (max-width: 360px) {
  .budget-option{
    display: block;
  }
  .custom-tab .nav-tabs li {
    width: 33.33%;
    padding: 5px 5px;
  }
  .feature-thumb-big,
  .feature-thumb-small {
    height: 60px;
  }
  .custom-form.register-form-1 {
    padding: 70px 34px 50px;
  }
  .custom-form {
    padding: 30px 15px 30px;
  }
  .container {
    padding: 0 15px;
  }
  .custom-form.signup_screen {
    padding: 30px 15px 30px;
  }
  .registered-vendor-btn h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .setting-list ul:last-child {
    margin-left: 10px;
  }
  .middle-section .event-sec ul li {
    width: 100%;
  }
}
@media only screen and (max-width: 345px) {
  .signup-signin-sec {
    display: flex;
    gap: 2px;
  }
}

/*.setting-cards .sky-btn:hover {
    background: #ffff;
    border-color: #1FBCFF;
    text-decoration: none;
}*/
/*.sky-btn {
    background-color: #1FBCFF;
    padding: 9px 41px 9px 41px;
    border-radius: 47px;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s ease;
}*/
/*2745*/
/* @media only screen and (max-width: 480px) {
      .signup-signin-sec {
          width: 100%;
          gap: 7px;
      }

      .signup-signin-sec .btn {
          padding: 6px 15px !important;
          height: 30px !important;
          font-size: 11px !important;
          min-width: 85px;
      }
      .signup-signin-sec figure a {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
      }
  } */

@media only screen and (max-width: 1360px) {
  /* section.sidebar-content-wr .right-content-block a.btn {
    padding: 10px;
    width: 100%;
  } */
  section.sidebar-content-wr .right-top-text > div {
    padding: 0 10px;
  }
  section.sidebar-content-wr .right-top-text select.events-form-control {
    width: 100%;
  }
  .chats-outer-block .user-review-right {
    padding: 25px 10px !important;
  }
  .user-review-block .user-review-img {
    display: flex;
  }
  .reviewer-review-top.chat-icon-right p {
    /* word-break: break-all; */
  }

  .sidebar-content-wr {
    padding-bottom: 100px;
  }
  .chat-note-text {
    position: absolute;
    bottom: -78px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    /* padding: 20px; */
  }

}

@media only screen and (max-width: 600px) {
  .right-top-text {
    flex-wrap: wrap;
  }
  .right-top-text > div {
    display: block;
    width: 100%;
    text-align: center !important;
  }
  section.sidebar-content-wr .right-top-text select.events-form-control,
  section.sidebar-content-wr .right-content-block a.btn {
    width: 40%;
    /* margin: 0 auto; */
  }
  section.sidebar-content-wr .right-top-text > div {
    padding-bottom: 15px;
  }
  .signup-signin-sec a {
    font-size: 11px !important;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .vendor-popup-slider-content.video-width {
    padding: 0 20px;
}
}
@media only screen and (max-width: 991px) and (min-width: 767px)  {
.home-filter-section {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.home-banner-sec{
  margin-bottom: 30px !important;
  margin-top: 30px !important;
}
}
