/* 
@import url(./css/bootstrap.min.css);
@import url(./css/demo.css);
@import url(./css/paper-dashboard.css);
@import url(./css/themify-icons.css); */
.map-img {
    height: 337px;
    border-radius: 15px;
}

h5.unreadMessage {
    /* text-align: right; */
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    background-color: red;
    color: white;
    font-size: 15px;
    /* padding: 2px 6px; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
}

.ReactModal__Overlay {
    z-index: 3;
    background: transparent !important;
}

.ReactModal__Content {
    /* your custom styles here */
}

.custom-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
  }

  .ratingPopup .form-group label{
    color: black !important;
  }

  .accordion-btn-for-update {
    margin-left: auto;
}

.event-payment-btn {
    display: flex;
}

.reply-btn-submit{
    float: right;
    margin-top: 15px !important;
}

.reply-textbox {
    margin-top: 20px;
}

.my-custom-style {
    background-color: #ff0000 !important
}

.chat-link-icon, .review-reply-textarea-form {
    display: inline-block;
    vertical-align: middle;
}
.review-reply-textarea-form {
    width: 100%;
    margin-left: 7px;
}
.review-reply-textarea-form textarea {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.4);
    padding: 15px 18px 10px;
    background: #050C20;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    resize: none;
    height: 50px;
    width: 100%;
}
.review-reply-textarea, .review-reply-submit-btn {
    display: inline-block;
    vertical-align: middle;
    height: 50px;
}
.review-reply-textarea {
    width: 91%;
}

.chat-img-container{
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: end;
}

.chat-img-container-left{
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
}

.img-radius{
    border-radius: 15px;
}

.chat-img-src {
    width: 250px;
    height: 140px;
    object-fit: contain;
    background-color: #0e213b;
}
.chat-img-src-pdf {
    cursor: pointer;
}
.imagePreview {
    display: flex;
    justify-content: flex-start;
    background: #597b9b2b;
    border-radius: 10px;
    border: 1px solid #5091ce2b;
}

.imagePreviewPdf {
    display: flex;
    justify-content: flex-start;
    background: #597b9b2b;
    border-radius: 10px;
    border: 1px solid #5091ce2b;
}

.pdf-label {
    margin: 0 10px 0 0;
    font-size: 14px;
}
  
  .imageContainer {
    position: relative;
    background: #0e213b;
    border-radius: 10px;
  }
  
  .imageContainer img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  
  .crossButton {
    /* position: absolute !important;
    top: 0;
    right: 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s; */
    
    position: absolute !important;
    top: 5px;
    right: 5px;
    background: linear-gradient(90deg, #58ABE7 0%, #5858E7 42.95%, #FF5238 100%);
    border: 50px;
    color: white;
    height: 28px;
    width: 27px;
    opacity: 0;
    border-radius: 50%;
    transition: opacity 0.3s;
  }

  .crossButtonPdf {
    /* position: absolute !important;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s; */

    position: absolute !important;
    top: 5px;
    right: 5px;
    background: linear-gradient(90deg, #58ABE7 0%, #5858E7 42.95%, #FF5238 100%);
    border: 50px;
    color: white;
    height: 28px;
    width: 27px;
    opacity: 0;
    border-radius: 50%;
    transition: opacity 0.3s;
  }
  
  
 
  .imageContainer:hover .crossButton{
    opacity: 1 ;
    z-index: 91;
  }

  .imageContainer:hover .crossButtonPdf {
    opacity: 1 ;
    z-index: 91;
  }
  
.reply-date{
    float: right;
}

.right-top-text h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
}


.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7) !important; /* Black background with 50% opacity */
  
  }

  .manage-subs-card {
    display: flex;
  }

  p.expire-date {
    margin: 0;
}

.copy-st-btn {
    cursor: pointer;
    background: rgb(37, 211, 102);
    padding: 8px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copy-st-btn:hover {
    opacity: .8;
    transition: 0.5s;
    transform: translateY(-14%);
}

.event-listing-budget-block img {
    width: 28px;
    height: 28px;
}
.event-listing-service-block img {
    width: 28px;
    height: 28px;
}

.flag-content {
    display: flex !important;
    justify-content: end;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}
  
.react-calendar__month-view__weekdays {
    color: black !important;
}

.report-content {
    display: inline-block;
    position: relative;
    top: 5px;
    margin-left: 10px
}
.area-operates-slider-block .area-operates-slider-content .owl-nav {
    /* text-align: center; */
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    top: 50%;
  }

  /* .area-operates-slider-block .area-operates-slider-content .owl-stage > div.active:first-of-type {
    background-color: red !important;
  } */
  .area-operates-slider-block .area-operates-slider-content .owl-stage > div.active:first-of-type {
    background-color: red !important;
  }
  
  
  
  /* .area-operates-slider-block .area-operates-slider-content .owl-stage:last-child {
    padding-right: 70px !important;
  } */

  .apexcharts-tooltip {
  border: 1px solid #464646;
  }
  .privacy-policy-text-block .accordion{
    margin: 0 0 60px;
  }
  .displayNone{
    display: none;
  }