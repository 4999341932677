/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Regular.ttf') format('truetype');
} 

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: 'Montserrat', sans-serif;
}

@media (max-width: 767px) {
.content {
  display: none; /* Initially hide the content on screens smaller than 768px */
}

.show {
  display: block; /* Show the content on screens smaller than 768px */
  width: 100%;
    height: 100% !important;
}

.hide {
  display: none; /* Hide the content on screens smaller than 768px */
}

.content ul.list-unstyled {
  padding: 30px 0 0;
}
}

@media (max-width: 479px) {
  .divFixed{
    top: 135px !important;
    left: 250px !important;
    width: 295px;
    position: absolute;
    z-index: 111;
    display: block;
  line-height: 60px;
  }
}
@media (max-width: 413px) {
  .divFixed{
    top: 130px !important;
    left: 200px !important;
    width: 295px;
    position: absolute;
    z-index: 111;
    display: block;
  line-height: 60px;
  }
  
}
